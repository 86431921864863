import React from 'react';
import Logo from '../images/logo.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import {Link } from 'react-router-dom';
import { BsCaretDownFill } from "react-icons/bs";
import {useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
const stripePromise = loadStripe('pk_test_51NYTzDCcPvxw1TCbITLPIsEBloCen42N3VnBL5zXwSnIlwWOCft4WN3ByaMvHTrkrzoO69CRZxE9zk2inQ2t91AQ00imfbVnw8');
function HeaderExpire(){
	const navigate = useNavigate();
	const logout = () => {
		navigate('/')
	}

	const userData = JSON.parse(localStorage.getItem('tenent'))

	const subscribeLink = async (link) => {
		
		const url = 'http://localhost:8080';

		const stripe = await stripePromise;

	    const { error } = await stripe.redirectToCheckout({
	      lineItems: [{
	        price: 'price_1O2olSCcPvxw1TCbLFZTUGfg', // Replace with the ID of your price
	        quantity: 1,
	      }],
	      mode: 'subscription',
	      successUrl: `${url}/success`,
	      cancelUrl: `${url}/cancel`,
	      customerEmail: userData.email,
	    });

		//window.location.replace(link);
	}

	const createDate = moment(userData.created_at).format('YYYY-MM-DD');

		// let prescription = {
	  	// 	prescriptionDate: new Date(createDate),  // Today
	  	// 	prescriptionExpirationDate: 14  // Days to add
		// };

	let expireDate = moment(createDate).add(14, 'days');


		var a = moment();
		
		const trial = expireDate.diff(a, 'days')   // =1

		if(trial < 0){
			//navigate('/expire');
		}

	
	
	return(
		<>
		
		<div className="header_section">
			<div className="logo_section">
				<a href="/dashboard">
					<img src={Logo} width="100%" />
				</a>
			</div>

			<div className="navbar_section">
				<ul>
					<li className="link_section">
						<Link to="/dashboard">Stories</Link>
					</li>

					

					<li className="link_section">
						<Link to="/contact">Contacts</Link>
					</li>

					<li className="link_section">
						<Link to="/broadcast">Broadcast</Link>
					</li>

					<li className="link_section">
						
						<Link to="/settings">Settings</Link>
					</li>
					
				</ul>
			</div>

			<div className="user_name_section">
                <Dropdown>
			      <Dropdown.Toggle variant="success" id="dropdown-basic">
			        <div className="d-flex align-center">
			        	<div className="user_box">
			        		<h2>DA</h2>
			        	</div>

			        	<div className="text_section">
			        		<p>Welcome</p>
			        		<h6>Dilawar Abbas <BsCaretDownFill /></h6>
			        	</div>
			        </div>
			      </Dropdown.Toggle>

			      <Dropdown.Menu>
			      	<ul>
				        <li><Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item></li>
				    </ul>
			      </Dropdown.Menu>
			    </Dropdown>
			</div>
		</div>
		
		
		</>
	)
}

export default HeaderExpire;