import React,{useEffect,useState} from 'react';
import { fetchWrapper } from '../helpers';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import dots from '../../assets/images/dots.png';
import Image from 'react-bootstrap/Image';

import { useToast } from '@chakra-ui/react'

function UserList({user_type_id,show,loadModel}){
   const toast = useToast()
  const [list,setList] = useState([])
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListUser()
       
       
        
    }, [show]);


   const loadListUser = async () => {
      const url = `${backendUrl}/user/list?user_type_id=${user_type_id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

  const deleteUser = async (id)  => {

      const url = `${backendUrl}/user/delete?id=${id}`;
      const data  = await fetchWrapper.get(url)
      loadListUser();
      toast({
           position: 'top',
           variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been deleted.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
  }
  const changePassword = (rs) => {
         loadModel(rs,'changepassword')

  }

   const edit = (rs) => {
      loadModel(rs,'edit')

  }

 
    return (
      <div>
       {list.map(rs => {

          return (


                        

                  <div className="d-flex justify-content-between align-center user_section">
                     <div className="user_name_section">
                        <div className="user_box">
                           <h4>DA</h4>
                        </div>

                        <div className="text_section">
                           <h2>{rs.first_name} {rs.last_name}</h2>
                           <p>Last login was Friday, March 17, 2023 05:30 pm</p>
                        </div>
                     </div>

                     <div className="user_dropdown">
                        <Dropdown>
                           <Dropdown.Toggle id="dropdown-basic">
                             <Image
                                 src={dots}
                              />
                           </Dropdown.Toggle>

                           <Dropdown.Menu>
                              <ul>
                                <li><Dropdown.Item onClick={() => edit(rs)}>Edit</Dropdown.Item></li>
                                <li><Dropdown.Item onClick={() => changePassword(rs)}>Change Password</Dropdown.Item></li>
                                <li><Dropdown.Item onClick={() => deleteUser(rs.id)}>Delete</Dropdown.Item></li>
                            </ul>
                           </Dropdown.Menu>
                         </Dropdown>
                        
                    </div>
                  </div>

            )

       })}
         
      </div>
    );
  
}

export default UserList;
