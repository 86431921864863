import React,{useEffect,useState} from 'react';
import LeftNav from './leftnav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { fetchWrapper } from '../helpers';
import font from '@googleforcreators/fonts/fonts.json';
import { useToast } from '@chakra-ui/react'
function Typography(){



const toast = useToast()

	const [fontData,setFontData] = useState([])
	const [listData,setListData] = useState({})
	const [show,setShow] = useState(false)
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListData()
       loadFont()
       
        
    }, [show]);


   const loadFont = () => {

   			const fontList = font.map(item => {

   					 return {id:item.family,name:item.family}
   			})
   			setFontData(fontList)
   }


   const loadListData = async () => {
      const url = `${backendUrl}/tenent/by-id?id=1`;
      const data  = await fetchWrapper.get(url)

      if(data.data.settings != ''){

      	const settingsData =  JSON.parse(data.data.settings);
      	if(settingsData['typography']){


	      		setListData(settingsData['typography'])

	      	setForm({h1_font:listData.h1_font,h1_weight:listData.h1_weight,h1_size:listData.h1_size,h1_color:listData.h1_color,
      	h2_font:listData.h2_font,h2_weight:listData.h2_weight,h2_size:listData.h2_size,h2_color:listData.h2_color,
      	h3_font:listData.h3_font,h3_weight:listData.h3_weight,h3_size:listData.h3_size,h3_color:listData.h3_color,
      	para_font:listData.para_font,para_weight:listData.para_weight,para_size:listData.para_size,para_color:listData.para_color,
      	caption_font:listData.caption_font,caption_weight:listData.caption_weight,caption_size:listData.caption_size,caption_color:listData.caption_color,
      	label_font:listData.label_font,label_weight:listData.label_weight,label_size:listData.label_size,label_color:listData.label_color,
    	})

      	}
      	

      }
      
      
      setShow(true)

  }

	const [form, setForm] = useState({
	    h1_font: "",
	    h1_weight:"",
	    h1_size:"",
	    h1_color:"",

	    h2_font: "",
	    h2_weight:"",
	    h2_size:"",
	    h2_color:"",

	    h3_font: "",
	    h3_weight:"",
	    h3_size:"",
	    h3_color:"",


	    para_font: "",
	    para_weight:"",
	    para_size:"",
	    para_color:"",


	    caption_font: "",
	    caption_weight:"",
	    caption_size:"",
	    caption_color:"",


	    label_font: "",
	    label_weight:"",
	    label_size:"",
	    label_color:"",
	    
   
  	});


  	const handleChange = (e) => {

    	setForm({ ...form, [e.target.name]: e.target.value });
  }

  const submitButton = async (e) => {
    e.preventDefault();
   
    const data = {id:1,settings:form,type:'typography'};
    const url = `${backendUrl}/tenent/save-settings`;
  	await fetchWrapper.post(url,data)

  	toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been updated.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
    
    
  }

	return(
		<>
			<h4 className="inner_main_heading">Typography</h4>

								<Table className="table_section">
							      <thead>
							        <tr>
							          <th colSpan={4}>Name</th>
							          <th colSpan={3}>Font</th>
							          <th colSpan={2}>Weight</th>
							          <th colSpan={2}>Size</th>
							          <th colSpan={2}>Color</th>
							        </tr>
							      </thead>
							      <tbody>
							        <tr>
							          <td colSpan={4}>
							          	<h1>Heading 1</h1>
							          </td>
							          <td colSpan={3}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									        name="h1_font"  value={form.h1_font} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	{fontData?.map(item => {

										      		return (<option value={item.name}>{item.name}</option>)

										      	})}
										      	
										      	
								    		</Form.Select>
								      	</Form.Group>
								      </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									         name="h1_weight"  value={form.h1_weight} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">Medium</option>
										      	<option value="2">Bold</option>
										      	<option value="3">Extra Bold</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									         name="h1_size"  value={form.h1_size} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">32</option>
										      	<option value="2">16</option>
										      	<option value="3">14</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									         name="h1_color"  value={form.h1_color} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">#0AA7F3</option>
										      	<option value="2">#ffffff</option>
										      	<option value="3">#cccccc</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							        </tr>

							        <tr>
							          <td colSpan={4}>
							          	<h2>Heading 2</h2>
							          </td>
							          <td colSpan={3}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									         name="h2_font"  value={form.h2_font} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	{fontData?.map(item => {

										      		return (<option value={item.name}>{item.name}</option>)

										      	})}
								    		</Form.Select>
								      	</Form.Group>
								      </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									         name="h2_weight"  value={form.h2_weight} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">Medium</option>
										      	<option value="2">Bold</option>
										      	<option value="3">Extra Bold</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									         name="h2_size"  value={form.h2_size} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">32</option>
										      	<option value="2">16</option>
										      	<option value="3">14</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									         name="h2_color"  value={form.h2_color} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">#0AA7F3</option>
										      	<option value="2">#ffffff</option>
										      	<option value="3">#cccccc</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							        </tr>

							        <tr>
							          <td colSpan={4}>
							          	<h3>Heading 3</h3>
							          </td>
							          <td colSpan={3}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									        	 name="h3_font"  value={form.h3_font} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	{fontData?.map(item => {

										      		return (<option value={item.name}>{item.name}</option>)

										      	})}
								    		</Form.Select>
								      	</Form.Group>
								      </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									         name="h3_weight"  value={form.h3_weight} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">Medium</option>
										      	<option value="2">Bold</option>
										      	<option value="3">Extra Bold</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									         name="h3_size"  value={form.h3_size} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">32</option>
										      	<option value="2">16</option>
										      	<option value="3">14</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        	name="h3_color"  value={form.h3_color} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">#0AA7F3</option>
										      	<option value="2">#ffffff</option>
										      	<option value="3">#cccccc</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							        </tr>

							        <tr>
							          <td colSpan={4}>
							          	<p>Paragraph</p>
							          </td>
							          <td colSpan={3}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"

									        name="para_font"  value={form.para_font} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	{fontData?.map(item => {

										      		return (<option value={item.name}>{item.name}</option>)

										      	})}
								    		</Form.Select>
								      	</Form.Group>
								      </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="para_weight"  value={form.para_weight} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">Medium</option>
										      	<option value="2">Bold</option>
										      	<option value="3">Extra Bold</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="para_size"  value={form.para_size} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">32</option>
										      	<option value="2">16</option>
										      	<option value="3">14</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="para_color"  value={form.para_color} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">#0AA7F3</option>
										      	<option value="2">#ffffff</option>
										      	<option value="3">#cccccc</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							        </tr>

							        <tr>
							          <td colSpan={4}>
							          	<p>Caption</p>
							          </td>
							          <td colSpan={3}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="caption_font"  value={form.caption_font} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	{fontData?.map(item => {

										      		return (<option value="1">{item.name}</option>)

										      	})}
								    		</Form.Select>
								      	</Form.Group>
								      </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="caption_weight"  value={form.caption_weight} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">Medium</option>
										      	<option value="2">Bold</option>
										      	<option value="3">Extra Bold</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="caption_size"  value={form.caption_size} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">32</option>
										      	<option value="2">16</option>
										      	<option value="3">14</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="caption_color"  value={form.caption_color} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">#0AA7F3</option>
										      	<option value="2">#ffffff</option>
										      	<option value="3">#cccccc</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							        </tr>

							        <tr>
							          <td colSpan={4}>
							          	<p>LABEL</p>
							          </td>
							          <td colSpan={3}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="label_font"  value={form.label_font} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	{fontData?.map(item => {

										      		return (<option value={item.id}>{item.name}</option>)

										      	})}
								    		</Form.Select>
								      	</Form.Group>
								      </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="label_weight"  value={form.label_weight} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">Medium</option>
										      	<option value="2">Bold</option>
										      	<option value="3">Extra Bold</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="label_size"  value={form.label_size} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">32</option>
										      	<option value="2">16</option>
										      	<option value="3">14</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							          <td colSpan={2}>
							          	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Select aria-label="Default select example"
									        name="label_color"  value={form.label_color} onChange={ (e) => handleChange(e)}
									        >
										      	<option>Select</option>
										      	<option value="1">#0AA7F3</option>
										      	<option value="2">#ffffff</option>
										      	<option value="3">#cccccc</option>
								    		</Form.Select>
								      	</Form.Group>
							          </td>
							        </tr>
							      </tbody>
							    </Table>

							    <Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Save</Button>
			
		</>
	)
}

export default Typography;