import React,{useEffect,useState} from 'react';
import {Link,useNavigate } from 'react-router-dom';
import nostory from '../images/expire.png';
import Image from 'react-bootstrap/Image';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51NYTzDCcPvxw1TCbITLPIsEBloCen42N3VnBL5zXwSnIlwWOCft4WN3ByaMvHTrkrzoO69CRZxE9zk2inQ2t91AQ00imfbVnw8');
const Expire = () => {

	const navigate = useNavigate();

	const userData = JSON.parse(localStorage.getItem('tenent'))

	const subscribeLink = async () => {

		navigate('/subscribe');

		// const url = 'https://' + window.location.host;
		
		// const stripe = await stripePromise;

	  //   const { error } = await stripe.redirectToCheckout({
	  //     lineItems: [{
	  //       price: userData.Plan.sandbox_url, // Replace with the ID of your price
	  //       quantity: 1,
	  //     }],
	  //     mode: 'subscription',
	  //     successUrl: `${url}/success`,
	  //     cancelUrl: `${url}/cancel`,
	  //     customerEmail: userData.email,
	  //   });

		//window.location.replace(link);
	}

	return (
			<>

			<div style={{ paddingTop: 80,paddingBottom: 80 }}>
				<div className="d-flex align-center" style={{justifyContent:'center',textAlign:'center',marginBottom: 50}}>
					<h1 style={{fontSize:48,fontWeight:500,fontFamily: 'Montserrat',}}>Your trial has expired</h1>
				</div>

				<div className="d-flex align-center" style={{justifyContent:'center',textAlign:'center'}}>



	        <button type="button" onClick={() => subscribeLink()} className="primary">Subscribe Now</button>
	        
	      </div>

	      <div className="d-flex align-center" style={{justifyContent:'center',textAlign:'center'}}>
	        
	        <Image src={nostory} width="400" height="400" />
	      </div>
      </div>
     </>
		)
}

export default Expire;