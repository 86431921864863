import { Navigate } from 'react-router-dom';
import moment from 'moment';

import { history } from './helpers';

export { PrivateRoute };

function PrivateRoute({ children }) {
  
    const authUser = localStorage.getItem('user');
    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/" state={{ from: history.location }} />
    }else{



        const userData = JSON.parse(localStorage.getItem('tenent'))

        const createDate = moment(userData.created_at).format('YYYY-MM-DD');

    

          let expireDate = moment(createDate).add(14, 'days');


            var a = moment();
            
            const trial = expireDate.diff(a, 'days')   // =1

            
            
            if(trial < 0 && userData.payment_status != 'active' && history.location.pathname !== '/subscribe' && history.location.pathname !== '/expire'){
                return <Navigate to="/expire" />
              
            }
    }

    
    return children;
}