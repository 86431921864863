import React,{useEffect,useState} from 'react';
import { fetchWrapper } from './helpers';
import Button from 'react-bootstrap/Button';
import { loadStripe } from '@stripe/stripe-js';
import { BsCheckLg } from "react-icons/bs";
const stripePromise = loadStripe('pk_test_51NYTzDCcPvxw1TCbITLPIsEBloCen42N3VnBL5zXwSnIlwWOCft4WN3ByaMvHTrkrzoO69CRZxE9zk2inQ2t91AQ00imfbVnw8');
const Subscribe = () => {

	const [list,setList] = useState([]);
	const backendUrl = process.env.REACT_APP_API_URL
	useEffect(() => {
        loadList()
       
       
        
    }, []);

    const loadList = async () => {
      const url = `${backendUrl}/plan/list`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

  const subscribeLink = async (link) => {
		const userData = JSON.parse(localStorage.getItem('tenent'))
		
		const url = 'https://' + window.location.host;

		const stripe = await stripePromise;

	    const { error } = await stripe.redirectToCheckout({
	      lineItems: [{
	        price: link, // Replace with the ID of your price
	        quantity: 1,
	      }],
	      mode: 'subscription',
	      successUrl: `${url}/success`,
	      cancelUrl: `${url}/cancel`,
	      customerEmail: userData.email,
	    });

		
	}

	return (


			<div className="analytic_page">
        		<div className="d-flex mb-20 justify-content-between">
          			<h1 className="main_heading">Subscribe</h1>
          			
          		</div>
          		<div className="subscribe_box_section">
          		<ul>
          		{
          			 list?.rows?.map(rs => {

          			 	return (
          			 			<>
          			 			<li>
          			 				
          			 				<div style={{position: 'relative',backgroundColor: '#fff',boxShadow: '0px 0px 20px 0px #0000001A',width: '385px',borderRadius: 20
}}>
													<div style={{background: 'linear-gradient(180deg, #0966C4 0%, #0A83FF 100%)',paddingTop: 40,paddingBottom: 40,textAlign: 'center',borderTopLeftRadius: 20,
					borderTopRightRadius: 20}}>
														<h1 style={{fontSize: 48,fontWeight: 700,color: '#fff',fontFamily: 'Montserrat'}}>{rs.name}</h1>
														<p style={{fontSize: 20,fontWeight: 400,color: '#fff',fontFamily: 'Montserrat'}}>{rs.allow_story} Stories / month</p>
													</div>

													<div style={{padding: 30}}>
														<ul>
															<li><BsCheckLg /> Story Builder</li>
															<li><BsCheckLg /> Contacts Management</li>
															<li><BsCheckLg /> Broadcast via SMS</li>
															<li><BsCheckLg /> Twillio Integration</li>
														</ul>

														<div style={{marginTop: 40,marginBottom: 40}}>
															<h1 style={{fontSize: 32,color: '#2D2D2D',fontWeight: 600,fontFamily: 'Montserrat',display: 'flex',justifyContent: 'center'}}><span style={{fontSize: 24}}>$</span>{rs.monthly_cost} <span style={{color: '#ACACAC'}}>/Month</span></h1>
															<p style={{fontSize: 16,fontWeight: 400,fontFamily: 'Montserrat',color: '#2D2D2D',textAlign: 'center'}}>Save {rs.yearly_discount}% on Annual Payment</p>
														</div>

														<div style={{textAlign: 'center',marginBottom: 30}}>
															<button onClick={() => subscribeLink(rs?.sandbox_url)} type="button" className="story_subscribe">Subscribe</button>
														</div>
													</div>
													{rs.name == 'River' ? (<div style={{top: -20,right: 20,backgroundColor: '#E30000',color: '#fff',display: 'inline-block',fontSize: 18,fontWeight: 700,fontFamily: 'Montserrat',padding: '5px 20px',borderRadius: 20,position: 'absolute'}}>Popular</div>) : null}
													
					          		</div>
          			 			</li>		
          			 			</>
          			 		)

          			 	})

          			 }
          			
          		</ul>
          		</div>
          	</div>
		)
}

export default Subscribe;