import React,{useEffect,useState,useMemo} from 'react';
import { fetchWrapper } from './helpers';
import {Link,useNavigate } from 'react-router-dom';
import {
  StoryEditor,
  InterfaceSkeleton,
} from "@googleforcreators/story-editor";
import { elementTypes } from '@googleforcreators/element-library';
import { registerElementType } from '@googleforcreators/elements';
import HeaderLayout from "./storyheader/HeaderLayout";

import { theme } from '@googleforcreators/design-system';

import {useLocation } from 'react-router-dom';
import { dark,darkThemeValue } from './Theme';
import saveStoryById from "./saveStoryById";
import MediaUpload from './MediaUpload';
import {
 
  getFonts,
  getMedia,
  updateMedia,
  deleteMedia,
  uploadMedia,
  
} from '../api/editor';
import useExportStory from '../app/storyExport/useExportStory';
const Editor = () => {

    const navigate = useNavigate();
    const { exportStory } = useExportStory();
    
  const apiCallbacks = {
   saveStoryById: async ({
  pages,
  globalStoryStyles,
  currentStoryStyles,
  content,
  title,
  story,
 
}) => {



      const storySaveData =  {
          title: {
            raw: title,
          },
          storyData: {
            version: 47,
            pages,
            currentStoryStyles,
          },
          stylePresets: globalStoryStyles,
          permalinkTemplate: "https://example.org/web-stories/%pagename%/",
        };


        const backendUrl = process.env.REACT_APP_API_URL
        window.localStorage.setItem("STORY_MARKUP", content);

        const url = `${backendUrl}/story/create`;
        const dd = await fetchWrapper.post(url,storySaveData);
       
        navigate('/edit',{state:{ id: dd.data.id }})
        Promise.resolve({})
    },
   getFonts,
   getMedia,
    uploadMedia,
    updateMedia,
    deleteMedia,
  };


const config = useMemo(() => {
    return {
     
      capabilities: {
        hasUploadMediaAction: true,
      },
      apiCallbacks: apiCallbacks,
      MediaUpload,
    };
  }, []);




  elementTypes.forEach(registerElementType);

  
 
  const story = {};



  theme.colors.bg = darkThemeValue.darkTheme.bg
  theme.colors.fg = darkThemeValue.darkTheme.fg
  theme.colors.border = darkThemeValue.darkTheme.border
  theme.colors.form = darkThemeValue.darkTheme.form
  theme.colors.divider = darkThemeValue.darkTheme.divider
  theme.colors.shadow = darkThemeValue.darkTheme.shadow
  theme.colors.interactiveBg = darkThemeValue.darkTheme.interactiveBg
  theme.colors.interactiveFg = darkThemeValue.darkTheme.interactiveFg
  theme.colors.gradient = darkThemeValue.darkTheme.gradient

  // theme.colors.bg.secondry = '#fff'

  // theme.colors.fg.primary = '#000'
  // theme.colors.fg.secondry = '#000'

  // theme.colors.border.secondry = '#000'

  return (
    

      <>
      
      <StoryEditor  config={config} initialEdits={{ story }}>
        
        <InterfaceSkeleton  header={<HeaderLayout />} />
        </StoryEditor>


      </>
    
   
  );
};

export default Editor;