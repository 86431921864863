/*
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import {
  DropDown,
  PLACEMENT,
  useSnackbar,
  Icons
} from '@googleforcreators/design-system';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import { __ } from '@googleforcreators/i18n';
import { useStory,useHistory } from '@googleforcreators/story-editor';
import { css } from 'styled-components';
import axios from 'axios';
import {Link,useNavigate } from 'react-router-dom';
/**
 * Internal dependencies
 */
import { escapeHTML } from '../../../utils';
import calculateStoryLength from './video-export/calculateStoryLength';
import doVideoExport from './video-export/doVideoExport';
import eyeIcon from './inline-icons/eye_icon.svg';

import useExportStory from '../../../app/storyExport/useExportStory';
import { useStoryStatus } from '../../../app/storyStatus';
import { LOCAL_STORAGE_CONTENT_KEY } from '../../../consts';
import getInitialStoryState from '../../../utils/getInitialStoryState';

const selectButtonCSS = css`
  height: 40px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  
  font-size: 14px;
  font-weight: 400;
  color: #000;
  border-radius: 5px;
  span {
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }
  
  
  margin-right: 0px;
`;

const ACTION_OPTIONS = [
  {
    label: __('Preview', 'web-stories'),
    value: 1,
  },
  {
    label: __('Publish', 'web-stories'),
    value: 2,
    
  },
  {
    label: __('Story Settings', 'web-stories'),
    value: 3,
  },
  {
    label: __('Reset', 'web-stories'),
    value: 4,
  },
];
const PREVIEW_TARGET = 'story-preview';
const backendUrl = process.env.REACT_APP_API_URL
function getPreviewLink() {

  return process.env.REACT_APP_API_URL + '/preview.html';
}
function DropdownButtons() {
  const [selectedValue, setSelectedValue] = useState(1);
  const { saveStory, pages, defaultPageDuration, title, reducerState } =
    useStory(
      ({
        actions: { saveStory },
        state: {
          story: { defaultPageDuration, title },
          pages,
        },
        internal: { reducerState },
      }) => ({
        saveStory,
        pages,
        defaultPageDuration,
        title,
        reducerState,
      })
    );
  const { current, selection, story } = reducerState;
  const { showSnackbar } = useSnackbar();
  const { exportStory } = useExportStory();
  const navigate = useNavigate();

   const {
    internal: { restore },
  } = useStory();
  const {
    actions: { clearHistory },
  } = useHistory();
  const resetStory = useCallback(() => {
    if (
      !window.confirm(
        __('Are you sure you want to reset all changes?', 'web-stories')
      )
    ) {
      return;
    }
    window.localStorage.removeItem(LOCAL_STORAGE_CONTENT_KEY);
    clearHistory();
    restore(getInitialStoryState());
  }, [restore, clearHistory]);

  const {
    state: { isImporting, isExporting },
  } = useStoryStatus(({ state }) => ({
    state,
  }));


  const handleActions = useCallback(
    async (_event, value) => {
      setSelectedValue(value);
      if(value === 1){

        let popup;
        const tenentData = JSON.parse(localStorage.getItem('tenent'));
        const preUrl  = `https://wooshstories.com/story/${tenentData.domain}/${story.storyId}/index.html`
        const playgroundPreviewLink = preUrl;// backendUrl + '/story/' + story.storyId + '/index.html' ;
        popup = window.open('about:blank', PREVIEW_TARGET);

        popup.document.write(
              `<script>
                 setTimeout(function() {
                   location.replace(${JSON.stringify(playgroundPreviewLink)});
                 }, 500);
               </script>`
            );

        
      }
      if (value === 2) {
        
         await exportStory();
         showSnackbar({
          message: 'Story has been published',
          dismissable: true,
        });
        
      } else if (value === 3) {
          await saveStory();
          navigate('/story-settings',{state:{ id: story.storyId }})
      } else if (value === 4) {
        resetStory()
      }
    },
    [
      current,
      defaultPageDuration,
      pages,
      saveStory,
      selection,
      showSnackbar,
      story,
      title,
    ]
  );
  return (
    <DropDown
      ariaLabel={__('Story Actions', 'web-stories')}
      placeholder={'Actions'}
      options={ACTION_OPTIONS}
      
      onMenuItemClick={handleActions}
      selectedValue={selectedValue}
      popupFillWidth={false}
      selectButtonStylesOverride={selectButtonCSS}
    />
  );
}
export default DropdownButtons;
