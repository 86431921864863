import React,{useState,useEffect} from 'react';

const Embed = ({rs}) => {

	const backendUrl =   process.env.REACT_APP_API_URL
	 const storyUrl = process.env.REACT_APP_STORY_URL
	 const iframeCode = `
    <iframe width="560" height="315" src="${storyUrl}/${rs?.Tenent?.domain}/${rs.id}/index.html" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  `;

	
	return (
			<>
				<div className="d-flex" style={{gap:'20px'}}>
					<div style={{width: '50%'}}>
						<iframe src={`${storyUrl}/${rs?.Tenent?.domain}/${rs.id}/index.html`} width="100%" height="360" frameborder="0"></iframe>
					</div>
					<div style={{width: '50%'}}>
						<textarea id="iframeCode" value={iframeCode} readOnly rows="9" />
					</div>
				</div>
			
			
			</>
		)
}

export default Embed;