import React,{useState,useEffect} from 'react';
import Contact from './components/Contact';
import Analytic from './components/Analytic';
import Settings from './components/Settings';
import StorySettings from './components/StorySettings';
import Editor from './components/Editor';
import EditorEdit from './components/EditorEdit';
import WelcomePage from './components/WelcomePage';
import Login from './components/Login';
import DomainError from './components/DomainError';
import Expire from './components/Expire';

import Error from './components/Error';
import Success from './components/success';
import Subscribe from './components/Subscribe';

import User from './components/User';
import BroadCastPage from './components/BroadCast'
import BroadCastDetail from './components/BroadCastDetail'
import BroadCastDetailView from './components/BroadCastDetailView'
import BroadCastAnalytic from './components/BroadCastAnalytic'
import Preview from './components/Preview';
import { BrowserRouter, Route, Routes, Link,Navigate, useNavigate,useLocation } from 'react-router-dom';
import Header from './components/Header'
import HeaderExpire from './components/HeaderExpire'
import { history } from './components/helpers';
import { PrivateRoute } from './components';
import { ChakraProvider } from '@chakra-ui/react'
import { fetchWrapper } from './components/helpers';

function App() {

  
  history.navigate = useNavigate();
  history.location = useLocation();

  const navigate = useNavigate();

const backendUrl = process.env.REACT_APP_API_URL
 const [loader, setLoader] = useState(false);
  const checkDomain = async () => {

     const url = `${backendUrl}/tenent/check-domain`;
     // const subdomain = 'ursanir';//window.location.host.split('.')[0];
     const subdomain =  backendUrl ==  'https://backend.wooshstories.com' ? window.location.host.split('.')[0] : 'ursanir';//window.location.host.split('.')[0];
     
    

     
      const data  = await fetchWrapper.post(url,{domain:subdomain})

      if(!data.data){
       
           navigate('/domain-error')
      }else{
        
        localStorage.setItem("tenent", JSON.stringify(data.data));
      }
    



  
}

  
  // useEffect(() => {
  //   if(window.location.pathname != '/error'){
  //       checkDomain();
  //   }
    
  // }, []);


     
    return (
      
      <>

      {history.location.pathname !== '/' && history.location.pathname !== '/domain-error' ? <Header/> : null}


      
        
          
          <Routes>
            <Route exact path="/" element={<ChakraProvider><Login /></ChakraProvider>} />
            <Route exact path="/domain-error" element={<ChakraProvider><DomainError /></ChakraProvider>} />

            <Route exact path="/error" element={<ChakraProvider><Error /></ChakraProvider>} />
            <Route exact path="/success" element={<ChakraProvider><Success /></ChakraProvider>} />

            <Route exact path="/expire" 


                element={
              <PrivateRoute>
                <ChakraProvider><Expire /></ChakraProvider>
                </PrivateRoute>

              }

            />

            <Route exact path="/subscribe" 


                element={
              <PrivateRoute>
                <ChakraProvider><Subscribe /></ChakraProvider>
                </PrivateRoute>

              }

            />


            <Route exact path="/contact" element={
              <PrivateRoute>
                <ChakraProvider><Contact /></ChakraProvider>
                </PrivateRoute>

              } />
            <Route exact path="/analytic" element={
              <PrivateRoute>
              <ChakraProvider><Analytic /></ChakraProvider>
              </PrivateRoute>
            } />
            <Route path="/settings" element={
                <PrivateRoute>
                <ChakraProvider><Settings /></ChakraProvider>
                </PrivateRoute>
              } />
            <Route path="/story-settings" element={
                <PrivateRoute>
                <ChakraProvider><StorySettings /></ChakraProvider>
                </PrivateRoute>
              } />
            <Route path="/create-new" element={
              <PrivateRoute>
                <Editor />
               </PrivateRoute> 
              } />
            <Route path="/edit" element={
                <PrivateRoute>
                <EditorEdit />
                </PrivateRoute>
              } />
            <Route path="/user" element={
              <PrivateRoute>
              <ChakraProvider><User /></ChakraProvider>
              </PrivateRoute>
            } />
            <Route path="/dashboard" element={

                <PrivateRoute>
                                <ChakraProvider><WelcomePage /></ChakraProvider>
                                
                            </PrivateRoute>

              } />
              <Route path="/broadcast" element={

                <PrivateRoute>
                                <ChakraProvider><BroadCastPage /></ChakraProvider>
                                
                            </PrivateRoute>

              } />
              <Route  path="/broadcastdetail" element={

                <PrivateRoute>
                                <ChakraProvider><BroadCastDetail /></ChakraProvider>
                                
                            </PrivateRoute>

              } />


              <Route  path="/broadcastdetailview" element={

                <PrivateRoute>
                                <ChakraProvider><BroadCastDetailView /></ChakraProvider>
                                
                            </PrivateRoute>

              } />


              <Route  path="/broadcastanalytic" element={

                <PrivateRoute>
                                <ChakraProvider><BroadCastAnalytic /></ChakraProvider>
                                
                            </PrivateRoute>

              } />
            
          </Routes>
          
      </>
      
    );
  
}

export default App;
