import React,{useEffect,useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Plus from '../../assets/images/plus.svg';
import Cross from '../../assets/images/cross.svg';
import Image from 'react-bootstrap/Image';
import { fetchWrapper } from '../helpers';
import { useToast,Text } from '@chakra-ui/react'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const Mobile = ()=>{
	const tenentData = JSON.parse(localStorage.getItem('tenent'));
	const tenent_id = tenentData?.id
	const [show,setShow] = useState(false)
	const backendUrl = process.env.REACT_APP_API_URL
	const toast = useToast();
const [listData,setListData] = useState({})
	const [storyList,setStoryList] = useState([]);
	const [form, setForm] = useState({
	    mobile_api: "",
	    mobile_story_id:"",
	    
   
  	});

	const handleChange = (e) => {

    	setForm({ ...form, [e.target.name]: e.target.value });
  	}

  	useEffect(() => {

        loadStoryList()
       
        loadListData(tenent_id)
        
    }, [show]);

    const loadListData = async (tenent_id) => {
      const url = `${backendUrl}/tenent/by-id?id=${tenent_id}`;
      const data  = await fetchWrapper.get(url);
      if(data.data.settings != ''){

      	const settingsData =  JSON.parse(data.data.settings);
      	if(settingsData['mobile']){


	      		setListData(settingsData['mobile'])

	      	setForm({mobile_api:listData.mobile_api,mobile_story_id:listData.mobile_story_id
	    		})

      	}
      	

      }

      
      
      setShow(true)
  	}


   	const loadStoryList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/story/list`;
      const data  = await fetchWrapper.get(url)
      setStoryList(data.data)
	}

	

	const GenerateKey = async(tenent_id)=>{
		console.log(tenent_id)
		let  data = {
			...form,
			mobile_api:uuidv4(),
		}
		setForm((prev) => ({
	        ...prev,
	        mobile_api: data.mobile_api
	    }));
		

		const generate = {id:tenent_id,settings:data,type:'mobile'}
	  const url = `${backendUrl}/tenent/save-settings`;
	  await fetchWrapper.post(url,generate)

	  let  Tenent_data = {
			api_key:data.mobile_api,
			id :tenent_id

		}
		  
	       
	    const tennet_url = `${process.env.REACT_APP_API_URL}/tenent/update`;
  		await fetchWrapper.post(tennet_url,Tenent_data)


		toast({
	  		 	  position: 'top',
	  		 	  variant:'top-accent',
	          title: 'Congratulation.',
	          description: "Record has been updated.",
	          status: 'info',
	          duration: 1000,
	          isClosable: true,
	        })  
	       
	    // const url = `${process.env.REACT_APP_API_URL}/customer/update`;
  		// await fetchWrapper.post(url,data)
  		// loadList();

	}

	const submitButton = async (e) => {
	    e.preventDefault();
	   
	    const data = {id:tenent_id,settings:form,type:'mobile'};
	    const url = `${backendUrl}/tenent/save-settings`;
	  	await fetchWrapper.post(url,data)

	  	toast({
	  		 	  position: 'top',
	  		 	  variant:'top-accent',
	          title: 'Congratulation.',
	          description: "Record has been updated.",
	          status: 'info',
	          duration: 1000,
	          isClosable: true,
	        })
	    
	    
	  }
	return(
		<>
			<h4 className="inner_main_heading">Mobile App</h4>
			<Form className="form_section">
				<div className="w-100">
					

					<div className="w-50">
						<Form.Group className="field_section" style={{ marginBottom: '10px' }} controlId="exampleForm.ControlInput1">
					        <Form.Label>Select Story</Form.Label>
							<Form.Select aria-label="Default select example"
							name="mobile_story_id"  value={form.mobile_story_id} onChange={ (e) => handleChange(e)}
							>
						      	<option>Please select the Story</option>
						      	{storyList?.map(rs => {

						      		return (<option value={rs.id}>{rs.title}</option>)
						      	})}
				    		</Form.Select>
			    		</Form.Group>
			    		
			    		
					</div>	
					<Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Save</Button>
				

	    		
					<div className="mt-5">
		    		<div>
						<h4 className="inner_main_heading">How to Use</h4>
						<Form.Group className="field_section w-50" style={{ marginBottom: '10px' }} controlId="exampleForm.ControlInput1">
					        <Form.Label>API Key</Form.Label>
							<Form.Control 
					         name="mobile_api"  value={form.mobile_api} onChange={ (e) => handleChange(e)}
					        type="name" />
			    		</Form.Group>
			    		<div className="d-flex justify-content-between w-50">
			    			<div>
			    				<Text className="link_text" onClick={()=> GenerateKey(tenent_id)}>Generate API Key</Text>
			    			</div>
			    			<div>

			    				<Text className="link_text"  onClick={()=> GenerateKey(tenent_id)}>Re-Generate API Key</Text>
			    			</div>
			    		</div>
					</div>
					</div>


					<div className="mt-5">
		    		<div>
						<h4 className="inner_main_heading">Platform</h4>
						<Text className="link_text">React Native</Text>
						<Text className="link_text">Android (Kotlin)</Text>
						<Text className="link_text">Swift (iOS)</Text>
						<Text className="link_text">Flutter</Text>

					</div>
					</div>

				</div>
				
			</Form> 
		</>
	)
}

export default Mobile;
