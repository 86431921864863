import React from 'react';
import Form from 'react-bootstrap/Form';

function SelectField(props) {
	const {name ,list,title} = props
	return(
		<>
			<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
	        <Form.Label>{title}</Form.Label>
			<Form.Select aria-label="Default select example"

			 name={name}  value="value" onChange={ (e) => handleChange(e)}
			>
				{list?.map((item) => {
					return (
		      	<option value={item.id}>{item.name}</option>
		  		)
				})}
		      	
    		</Form.Select>
  		</Form.Group>
		</>
	);
}

export default SelectField;