import React,{useEffect,useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Image from 'react-bootstrap/Image';
import rightBar from '../images/rightbar.png';
import { fetchWrapper } from './helpers';
import UserList from './user/UserList'

import Button from 'react-bootstrap/Button';

import Create from './user/Create'
import Edit from './user/Edit'
import ChangePassword from './user/ChangePassword'
import {Model} from '../components/FormElement'

import dots from '../assets/images/dots.png';
function User(){
	const [list,setList] = useState([])
	const [show, setShow] = useState(false);
	const [userTypeId, setUserTypeId] = useState(0);
	const [rs, setRs] = useState(0);  
	const [modelType, setModelType] = useState('create');

	const backendUrl = process.env.REACT_APP_API_URL
	 useEffect(() => {
        loadList()
       
       
        
    }, []);


   const loadList = async () => {

      const url = `${backendUrl}/user-type/list`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

    const handleClose = () => setShow(false);
  const handleShow = (user_type_id) => {
  	setModelType('create')
  		setUserTypeId(user_type_id)	
  		setShow(true)
  }

  const loadModel = (rs,type) => {
  	 setRs(rs);
  	 setModelType(type)
  	 setShow(true)
  }

	return(
		<>
			
			<h4 className="inner_main_heading">Users</h4>
							{list.map(rs => {
								return (
								<>


									<div className="owner_section">
									<div className="d-flex justify-content-between mb-20">
										<div className="text_section">
											<h2>{rs.name}</h2>
											<p>Team Members can access all features apart from Billing</p>
										</div>
										{ rs.id == 1 ? <a href="#">Change Owner</a> : <a onClick={ () => handleShow(rs.id)}>Add User</a> }
											
										
									</div>

									
								</div>

								<div className="owner_section">
									


									<UserList loadModel={loadModel} show={show} user_type_id={rs.id} />

									
								</div>


								</>
								
								)
							})}
								

								
				{modelType == 'create' ? <Model title="Add User" handleClose={handleClose} show={show} children={<Create loadList={loadList} handleClose={handleClose}  user_type_id={userTypeId} />} /> : null}
							
				{modelType == 'edit' ? <Model title="Edit User" handleClose={handleClose} show={show} children={<Edit loadList={loadList} handleClose={handleClose}  rs={rs} />} /> : null}
		
				{modelType == 'changepassword' ? <Model title="Change Password" handleClose={handleClose} show={show} children={<ChangePassword loadList={loadList} handleClose={handleClose}  rs={rs} />} /> : null}
		</>
	);
}

export default User;