
import { fetchWrapper } from '../helpers';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import dots from '../../assets/images/dots.png';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useToast } from '@chakra-ui/react'
function List({list,show,loadModel,loadList}){

  const toast = useToast()

  
  const backendUrl =   process.env.REACT_APP_API_URL

   const edit = (rs) => {
      loadModel('edit',rs)

  }
  const inital = (name) => {

      return name.substr(0,1).toUpperCase();
  }

  const deleteContact = async(id) => {
    const aa = confirm("Are you sure delete this recored");
    if(aa){
        const url = `${backendUrl}/contact/delete-contact?id=${id}`;
      const data  = await fetchWrapper.get(url)
      loadList()

      toast({
              position: 'top',
              variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been deleted.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
    }
    
  }

 
    return (
      <div className="customer-tag">
            <div className="head_section">
               <h1 className="main_heading">Customer Tags</h1>

               <div>
                  

                  <Button className="primary" onClick={() => loadModel('create')}>Add Tags</Button>
               </div>
            </div>

            <div>
               <Table className="table_section">
                  <thead>
                    <tr>
                      <th>Name</th>
                      
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                     list?.map((rs,index) => {


                           return (



                           <tr key={index}>
                      <td>
                        <div className="user_section">
                           

                           <p>{rs.name}</p>
                        </div>
                      </td>
                      
                      <td>
                        <a className="edit_link" onClick={() => edit(rs)}>Edit</a> | 
                        <a className="delete_link">Delete</a>
                      </td>
                      
                    </tr>


                           )
                     })
                  }
                    
                    
                   

                    

                    

                    
                  </tbody>
                </Table>
            </div>
         </div>
    );
  
}

export default List;
