import React,{useEffect,useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import rightBar from '../images/rightbar.png';
import LeftNav from './settings/leftnav';
import dots from '../assets/images/dots.png';
import User from './User'
import Analytic from './settings/Analytic'
import Branding from './settings/Branding'
import Typography from './settings/Typography'
import Billing from './settings/Billing'
import Seo from './settings/Seo'
import { fetchWrapper } from './helpers';
import {useLocation } from 'react-router-dom';
function StorySettings(){

	let { state } = useLocation();
	const backendUrl =   process.env.REACT_APP_API_URL
  	const [list,setList] = useState({})
	const [settings,setSettings] = useState({})
	const [loading,setLoading] = useState(true)
	
  useEffect(() => {
        loadList()
       
       
        
    }, [loading]);


  const [form, setForm] = useState({
    title: "",
    publisher_name:settings.publisher_name,
    meta_tag:"",
    language_id:"",
   
  });

  const loadList = async () => {
      const url = `${backendUrl}/story/by-id?id=${state.id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
      const settingData = (data.data.settings) ? JSON.parse(data.data.settings) : [];
      
      setSettings(settingData)
      setForm({title:settingData.title,publisher_name:settingData.publisher_name,language_id:settingData.language_id,meta_tag:settingData.meta_tag})
      setLoading(false)
  }

 
	

	const handleChange = (e) => {

    	setForm({ ...form, [e.target.name]: e.target.value });
  }

  const submitButton = async (e) => {
    e.preventDefault();
   
    const data = {id:list.id,settings:form};
    const url = `${backendUrl}/story/save-settings`;
  	await fetchWrapper.post(url,data)
    
    
  }
	return (


			<div className="publish_setting_page">
				<div className="container">
					<div className="mb-20">
						<h2 className="main_heading">Story Publish Settings</h2>
						
					</div>
					
					<div className="d-flex">
						<div className="left_section">
							<div className="page_data">
								<h4 className="inner_main_heading">Publisher Details</h4>

								<Form className="form_section">
									<div className="me-20">
									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Story Title</Form.Label>
									        <Form.Control name="title"  value={form.title} onChange={ (e) => handleChange(e)}  
									        type="name" />
									      </Form.Group>
									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Publisher Name</Form.Label>
									        <Form.Control 
									        name="publisher_name" value={form.publisher_name} onChange={ (e) => handleChange(e)}
									        type="name" />
									      </Form.Group>
									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Meta Tags (Comma Separated)</Form.Label>
									        <Form.Control 
									        name="meta_tag" value={form.meta_tag} onChange={ (e) => handleChange(e)}

									        type="name" />
									      </Form.Group>

									      <Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Save</Button>
									</div>

									<div>
										<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Language</Form.Label>
											<Form.Select aria-label="Default select example"
											onChange={ (e) => handleChange(e)}
											name="language_id" value={form.language_id}
											>
										      	<option>Open this select menu</option>
										      	<option value="English">English</option>
										      	<option value="Arabic">Arabic</option>
										      	
								    		</Form.Select>
							    		</Form.Group>
									</div>
								</Form>   	
							</div>
						</div>

						<div className="right_section">
							<div style={{widht:'300px',height:'472px'}}>
			                    {list.status_id == 10 ? <iframe src={`${backendUrl}/story/${list.id}/index.html`} width="100%" height="472" frameborder="0"></iframe> : 


			                    	<Image src={rightBar} width="100%" height="472" />

			                 	}
			                      
			                    
			                  </div>
						</div>
					</div>
				</div>
			</div>

		)
}
export default StorySettings