import React,{useEffect,useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import {InputText,Form,SelectField,MultiSelectFieldCreate} from '../../components/FormElement'
import { useToast } from '@chakra-ui/react'

import {
   
    Field,
    
} from 'formik';

import { fetchWrapper } from '../helpers';




function Edit({loadList,handleClose,rs}){
	

	const toast = useToast()
	const backendUrl = process.env.REACT_APP_API_URL

	const [tagList,setTagList] = useState([])
	useEffect(() => {
        loadTagList()
        
       
       
        
    }, []);

    const loadTagList = async () => {
      const url = `${backendUrl}/api/customer-tags/list`;
      
       const data  = await fetchWrapper.get(url)
      setTagList(data.data)
      
     

  	}


	const [formData, setFormData] = useState({
    name: rs.name,
    phone: rs.phone,
    email: rs.email,
    id:rs.id,
    tags: (rs.tags !== '') ? JSON.parse(rs.tags) : [],
  
    
    
  });

  const FormSchema = Yup.object().shape({
	     name: Yup.string()
	          .required('Required'),
	    phone: Yup.string()
	          .required('Required')
	    
	   
	    
	     
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${backendUrl}/contact/update`;
  		 await fetchWrapper.post(url,data)
  		 loadList();


  		 toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been updated.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })

  		 handleClose();
	}

	


	return (

			<>
			<div className="create_user_form">
				<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 	>


			 	<InputText name="name" title="Name"  />
			 	<InputText name="phone" title="Contact Number"  />
			 	<InputText name="email" title="Email Address"  />
			 	
			 	
			 	
			 	
			 	<Field
    name="tags"
    title="Label"
    id="tags"
    placeholder="Multi Select"
    isMulti={true}
    component={MultiSelectFieldCreate}
    options={

    	tagList?.map(item => {

    		return {label:item.name,value:item.id}
    	})
    }
/>
<br />

		         <Button
				            mt={4}
				            colorScheme='teal'
				            className="primary"
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>
			</div>
			</>

	)
}

export default Edit;