import React from 'react';
import Logo from '../images/logo.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import {Link } from 'react-router-dom';
import { BsCaretDownFill } from "react-icons/bs";
import {useNavigate } from 'react-router-dom';

import moment from 'moment';
import { history } from './helpers';
import jwt from 'jwt-decode'

function Header(){
	const navigate = useNavigate();
	const logout = () => {
		localStorage.clear()
		navigate('/')
	}

	const userData = JSON.parse(localStorage.getItem('tenent'))

	const userDataLogin =  jwt(localStorage.getItem('user'))?.userdata;

	const subscribeLink = async () => {
		navigate('/subscribe')

		// const url = 'https://' + window.location.host;

		// const stripe = await stripePromise;

	    // const { error } = await stripe.redirectToCheckout({
	    //   lineItems: [{
	    //     price: 'price_1O2olSCcPvxw1TCbLFZTUGfg', // Replace with the ID of your price
	    //     quantity: 1,
	    //   }],
	    //   mode: 'subscription',
	    //   successUrl: `${url}/success`,
	    //   cancelUrl: `${url}/cancel`,
	    //   customerEmail: userData.email,
	    // });

		//window.location.replace(link);
	}

	const createDate = moment(userData.created_at).format('YYYY-MM-DD');

		// let prescription = {
	  	// 	prescriptionDate: new Date(createDate),  // Today
	  	// 	prescriptionExpirationDate: 14  // Days to add
		// };

	let expireDate = moment(createDate).add(14, 'days');


		var a = moment();
		
		const trial = expireDate.diff(a, 'days')   // =1
		
		

	const initial = (name) => {
		return name?.substr(0,1);
	}
	
	
	return(
		<>
		
		<div className="header_section">
			<div className="logo_section">
				<a href="/dashboard">
					<img src={Logo} width="100%" />
				</a>
			</div>
			
			<div className="navbar_section">
			
			{history.location.pathname !== '/expire' && history.location.pathname !== '/subscribe' ? (
				<ul>
					<li className="link_section">
						<Link to="/dashboard">Stories </Link>
					</li>

					

					<li className="link_section">
						<Link to="/contact">Contacts</Link>
					</li>

					<li className="link_section">
						<Link to="/broadcast">Broadcast</Link>
					</li>

					<li className="link_section">
						
						<Link to="/settings">Settings</Link>
					</li>
					
				</ul>
				) : null }
			</div>

			<div className="user_name_section">
			
                <Dropdown>
			      <Dropdown.Toggle variant="success" id="dropdown-basic">
			        <div className="d-flex align-center">
			        	<div className="user_box">
			        		<h2>{initial(userDataLogin.full_name)}</h2>
			        	</div>

			        	<div className="text_section">
			        		<p>Welcome</p>
			        		<h6>{userDataLogin.full_name}<BsCaretDownFill /></h6>
			        	</div>
			        </div>
			      </Dropdown.Toggle>

			      <Dropdown.Menu>
			      	<ul>
				        <li><Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item></li>
				    </ul>
			      </Dropdown.Menu>
			    </Dropdown>

			</div>
		</div>
		
		{userData.payment_status == 'trial' && history.location.pathname !== '/expire' ? (


				<div className="header_bottom_section">
			
					<p>You are currently on <b>14 Days Free Trial Account.</b> Your trial will expire in {trial} days. </p>
					<button onClick={() => subscribeLink()} type="button" className="subscribe_button">Subscribe</button>
				</div>

			) : null}
		
		</>
	)
}

export default Header;