import React,{useEffect,useState} from 'react';
import reactLogo from 'assets/img/react_logo.svg';
import {Link,useNavigate } from 'react-router-dom';
import { fetchWrapper } from './helpers';
import Image from 'react-bootstrap/Image';
import rightBar from '../images/rightbar.png';
import chart from '../images/chart.png';
import whatsapp from '../images/whatsapp.png';
import nostory from '../images/no-story.png';
import { BsTrashFill } from "react-icons/bs";
import { ImEmbed } from "react-icons/im";
import Button from 'react-bootstrap/Button';
import { COMMON_MIME_TYPE_MAPPING } from '../consts';
import { isBlobURL } from '../utils';
import { PAGE_RATIO, PAGE_WIDTH } from '@googleforcreators/units';

import  {PreviewPage}  from '@googleforcreators/story-editor';


import  DisplayPage  from './DisplayPage';
import { renderToStaticMarkup } from 'react-dom/server';
import axios from 'axios';
import {Model} from '../components/FormElement'
import ViewEmbed from './ViewEmbed'
function WelcomePage(){
  const navigate = useNavigate();
  const backendUrl =   process.env.REACT_APP_API_URL
  const storyUrl = process.env.REACT_APP_STORY_URL
  const [list,setList] = useState([])
  const [record,setRecord] = useState(false);

  const [show, setShow] = useState(false);
  const [modelType, setModelType] = useState('');
  const [rs, setRs] = useState([]);  

  useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
      const url = `${backendUrl}/story/list`;
      setRecord(false);
      const data  = await fetchWrapper.get(url)
      setList(data.data)
      setRecord(true)
  }

  const goToPage = (id) => {
     navigate('/edit',{state:{ id: id }})
  }

  const deleteStory = async (id) => {
    const aa = confirm("Are you sure delete this recored");
    if(aa){
      const url = `${backendUrl}/story/delete?id=${id}`;
      const data  = await fetchWrapper.get(url)
      loadList()
    }
  }

  const loadModel = (type,rs=[]) => {
     setRs(rs)
     setModelType(type)
     setShow(true)
  }
  const handleClose = () => setShow(false);
  
    return (
      <>
      {
        modelType == 'veiw' ? 
        <Model title="Embed" handleClose={handleClose} show={show} size="lg"  centered="centered"
        children={<ViewEmbed rs={rs} handleClose={handleClose}   />} /> : null}


      <div className="analytic_page">
        <div className="d-flex mb-20 justify-content-between">
          <h1 className="main_heading">Stories</h1>
          {list.length > 0 ? (<Link to="/create-new" className="primary">Create New Story</Link>) : null}
          
        </div>

        <div className="story_box_section">
        <ul>

          {list.length > 0 ? 


              
                list?.map(rs => {
              const dd = JSON.parse(rs.content);

              
            


              const page = dd.storyData.pages[0];
             
              return (
                <>
                <li key={rs.id}>
                  
                  
                  
                
                  <div className="story_card" >

                  <div style={{widht:'300px',height:'472px'}}>
                    {rs.status_id == 10 ? <iframe src={`${storyUrl}/${rs?.Tenent?.domain}/${rs.id}/index.html`} width="100%" height="472" frameborder="0"></iframe> : 


                    <Image src={rightBar} width="100%" height="472" />

                  }
                      
                    
                  </div>
                    
                 
                    
                   
                    <div className="text_section">
                      <h3>{rs.title != '' ? rs.title : 'Untitled'} </h3>

                      <div className="d-flex justify-content-between">
                        <div className="icon_section">
                          
                        </div>


                        <div className="d-flex align-center" style={{gap:'10px'}}>
                          
                            <div className="delete_icon">
                              <a  onClick={() => deleteStory(rs.id)}>
                                <BsTrashFill />
                              </a>
                            </div>
                            <div className="" onClick={() => loadModel('veiw', rs)}>
                              <a>
                                <ImEmbed fontSize="21px" cursor="pointer"/>
                              </a>
                            </div>


                            
                            <Button onClick={() => goToPage(rs.id)}>Edit</Button>
                      </div>
                      </div>
                    </div>

                    
                  </div>
                  </li>
                </>
               )
            }

              )

             : null}

             {

                list.length == 0 && record ? 

                (

                <>
                <div className="d-flex align-center" style={{justifyContent:'center',textAlign:'center'}}>
                  <Link to="/create-new" className="primary">Create Your First Story</Link>
                  
                </div>

                <div className="d-flex align-center" style={{justifyContent:'center',textAlign:'center'}}>
                  
                  <Image src={nostory} width="500" height="500" />
                </div>
                </>

            )
                : null
             }

          
            </ul>
          </div>
      </div>
      </>
    );
  }


export default WelcomePage;
