import React,{useEffect,useState,useMemo} from 'react';
import { fetchWrapper } from './helpers';

import {
  StoryEditor,
  InterfaceSkeleton,
} from "@googleforcreators/story-editor";
import { elementTypes } from '@googleforcreators/element-library';
import { registerElementType } from '@googleforcreators/elements';
import HeaderLayout from "./storyheader/HeaderLayout";

import { theme } from '@googleforcreators/design-system';

import {useLocation } from 'react-router-dom';
import { dark,darkThemeValue } from './Theme';
import saveStoryById from "./saveStoryById";
import MediaUpload from './MediaUpload';

import {
 
  getFonts,
  getMedia,
  updateMedia,
  deleteMedia,
  uploadMedia,
  
} from '../api/editor';



const EditorEdit = () => {
  const backendUrl = process.env.REACT_APP_API_URL
  
  let { state } = useLocation();


  const [storyData,setStoryData] = useState([])
  const [storyView,setStoryView] = useState()
  useEffect(() => {
      
        loadData()
      
       /// loadSettings()
       
        
    }, [state.id]);


  


  const loadData = async () => {
      const url = `${backendUrl}/story/by-id?id=${state.id}`;
      const data  = await fetchWrapper.get(url)
     
      //setStoryData(data.data)
      setStoryView(JSON.parse(data.data.content))
      
      //window.localStorage.setItem("STORY_CONTENT", data.data.content);
  }

 
  const apiCallbacks = {

   saveStoryById: ({
  pages,
  globalStoryStyles,
  currentStoryStyles,
  content,
  title,
  story,
 
}) => {



      const storySaveData =  {
        
          title: {
            raw: title,
          },
          storyData: {
            
            version: 47,
            pages,
            currentStoryStyles,
          },
          stylePresets: globalStoryStyles,
          permalinkTemplate: "https://example.org/web-stories/%pagename%/",
        };



        window.localStorage.setItem("STORY_MARKUP", content);

        const url = `${backendUrl}/story/update?id=${state.id}`;
        fetchWrapper.post(url,storySaveData);

      Promise.resolve({})
    },
    getStoryById:async() => {

        const url = `${backendUrl}/story/by-id?id=${state.id}`;
      const data  = await fetchWrapper.get(url)
     
      //setStoryData(data.data)
      //setStoryView(JSON.parse(data.data.content))
      return JSON.parse(data.data.content)
    },
    getFonts,
    getMedia,
    uploadMedia,
    updateMedia,
    deleteMedia,
  };
  //elementTypes[0].defaultAttributes.font.family = 'Abel'
  
    const config = useMemo(() => {
    return {
      storyId: state.id,
      capabilities: {
        hasUploadMediaAction: true,
      },
      apiCallbacks: apiCallbacks,
      MediaUpload,
    };
  }, [state.id]);


  elementTypes.forEach(registerElementType);

  //const content = storyData.content;

  //const content = window.localStorage.getItem("STORY_CONTENT");
  
  //const story = content ? JSON.parse(content) : {};


  
  theme.colors.bg = darkThemeValue.darkTheme.bg
  theme.colors.fg = darkThemeValue.darkTheme.fg
  theme.colors.border = darkThemeValue.darkTheme.border
  theme.colors.form = darkThemeValue.darkTheme.form
  theme.colors.divider = darkThemeValue.darkTheme.divider
  theme.colors.shadow = darkThemeValue.darkTheme.shadow
  theme.colors.interactiveBg = darkThemeValue.darkTheme.interactiveBg
  theme.colors.interactiveFg = darkThemeValue.darkTheme.interactiveFg
  theme.colors.gradient = darkThemeValue.darkTheme.gradient

  // theme.colors.bg.secondry = '#fff'

  // theme.colors.fg.primary = '#000'
  // theme.colors.fg.secondry = '#000'

  // theme.colors.border.secondry = '#000'

  

  if (!storyView) {
    return <p>{'Please wait'}</p>;
  }

  return (
    


      <StoryEditor  config={config} initialEdits={{ storyView }}>
        
        <InterfaceSkeleton  header={<HeaderLayout  />} />
        </StoryEditor>

      
    
   
  );
};

export default EditorEdit;