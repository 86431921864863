import React,{useEffect,useState} from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import List from './customer-tag/List'
import Create from './customer-tag/Create'
import Edit from './customer-tag/Edit'
import { fetchWrapper } from './helpers';
import {Model} from '../components/FormElement'
function CustomerTag(){
	const [show, setShow] = useState(false);
	const [modelType, setModelType] = useState('create');
   const [rs, setRs] = useState(0);  

	const [listData,setListData] = useState([])
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadList()
       
       
        
    }, [show]);


   const loadList = async () => {
      const url = `${backendUrl}/api/customer-tags/list`;
      const data  = await fetchWrapper.get(url)
      setListData(data.data)
  }


	const handleClose = () => setShow(false);
  const handleShow = (user_type_id) => {
  	setModelType('create')
  		setUserTypeId(user_type_id)	
  		setShow(true)
  }

  const loadModel = (type,rs=0) => {
  	 setRs(rs)
  	 setModelType(type)
  	 setShow(true)
  }

	return(
		<>
			
			<List loadModel={loadModel} list={listData} loadList={loadList} show={show} />

			{
				modelType == 'create' ? 
				<Model title="Add Tags" handleClose={handleClose} show={show} 
				children={<Create loadList={loadList} handleClose={handleClose}   />} /> : null}



			{
				modelType == 'edit' ? 
				<Model title="Edit Tags" handleClose={handleClose} show={show} 
				children={<Edit loadList={loadList} rs={rs} handleClose={handleClose}   />} /> : null}



			

			
		</>
	)
}

export default CustomerTag;