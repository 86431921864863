import React,{useEffect,useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import {InputText,Form,SelectField,SelectFieldChakra} from '../../components/FormElement'
import { fetchWrapper } from '../helpers';
import { useToast } from '@chakra-ui/react'
import {Link,useNavigate } from 'react-router-dom';


function Create({loadList,handleClose}){
	const toast = useToast()
	const navigate = useNavigate();
	const [storyList,setStoryList] = useState([])
	const [typeList,setTypeList] = useState([])
	const backendUrl = process.env.REACT_APP_API_URL
	const [show,setShow] = useState(false)
	const [formData, setFormData] = useState({
    story_id: '',
    type_id: '',
  
    
    
  });

	useEffect(() => {
        loadStoryList()
         loadTypeList()
       
       
        
    }, []);


     const loadStoryList = async () => {
      const url = `${backendUrl}/story/list`;
      
       const data  = await fetchWrapper.get(url)
      setStoryList(data.data)
      
      setShow(true)

  	}

  	const loadTypeList = async () => {
      const url = `${backendUrl}/broadcast-type/list`;
      
       const data  = await fetchWrapper.get(url)
      setTypeList(data.data)
      
      setShow(true)

  	}

  const FormSchema = Yup.object().shape({
	     story_id: Yup.string()
	          .required('Required'),
	    type_id: Yup.string()
	          .required('Required'),
	   
	    
	     
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${backendUrl}/api/broadcast/create`;
  		 const apiData = await fetchWrapper.post(url,data)
  		 //loadList();
  		 toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been saved.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
  		 handleClose();
  		 navigate('/broadcastdetail',{state:{ id: apiData.data.id }})
  		 
	}

	


	return (

			<>
			<div className="create_user_form">
				<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 	>


			 	<SelectFieldChakra list={storyList} name="story_id" title="Story"  />

			 	<SelectFieldChakra list={typeList}  name="type_id" title="Broadcast via"  />


		         <Button
				            mt={4}
				            colorScheme='teal'
				            className="primary"
				            type='submit'
		          		>
		           		 Save
		          		</Button>
		          		

			</Form>
			</div>
			</>

	)
}

export default Create;