import React,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import { HStack, Box, Heading, Text, Button } from '@chakra-ui/react'
import {InputText,TextAreaField,SelectField,RadioComponent,InputDateField,MultiSelectFieldLabel} from './FormElement'
import { Link } from '@chakra-ui/react'
import { fetchWrapper } from './helpers';
import { Image } from '@chakra-ui/react'
import chart_img from '../assets/images/chart_img.png'
import rightbar from '../assets/images/rightbar.png'
import {useLocation,useNavigate } from 'react-router-dom';

import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";

highchartsMore(Highcharts);
solidGauge(Highcharts);

import moment from 'moment-timezone';
import {
    Formik,
    Form,
    Field,
    ErrorMessage,
    enableReinitialize,
    useFormikContext,
    useField,
    useFormik,
} from 'formik';
import { useToast } from '@chakra-ui/react'
function BroadCastDetail(){




const toast = useToast()

	let { state } = useLocation();
	const navigate = useNavigate();
	 const tenentData = JSON.parse(localStorage.getItem('tenent'));

	const [rs,setRs] = useState({})
	const [shortLink,setShortLink] = useState('');
	const [longLink,setLongLink] = useState('');
	const [tagList,setTagList] = useState([])
	const [schTypeVal,setSchTypeVal] = useState('')
	const [audiance,setAudiance] = useState({total_contact:0,target_contact:0})


	const [formData,setFormData] = useState({})
	const backendUrl = process.env.REACT_APP_API_URL
  	useEffect(() => {
      
        loadData()
      	loadTagList()
       	
        
    }, [state.id]);

const contactCount = async (labelValue) => {


      const url = `${backendUrl}/api/broadcast/contact-count?label_value=${labelValue}&broadcast_id=${state.id}`;
      console.log(url)
       const data  = await fetchWrapper.get(url)
      
      setAudiance(data)
     

}
const loadTagList = async () => {
      const url = `${backendUrl}/api/customer-tags/list`;
      
       const data  = await fetchWrapper.get(url)
      setTagList(data.data)
      
     

}
  const loadData = async () => {
      const url = `${backendUrl}/api/broadcast/by-id?id=${state.id}`;
      const data  = await fetchWrapper.get(url)
     
     
      setRs(data.data)
      setSchTypeVal(data.data.schdule_type)
     

      
     
      setFormData({
		    message: data.data.message == ""  || data.data.message === null ? '{storyurl}' :  data.data.message,
		  	story_url:`https://wooshstories.com/story/${tenentData.domain}/${data.data?.story_id}/index.html`,
		    //schdule_type: 'L',
		    schdule_type: data.data.schdule_type,
		    schedule: moment(data.data.schedule).format('YYYY-MM-DD HH:mm'),

		    labels:  (data.data.labels !== null) ? JSON.parse(data.data.labels) : [],
		    story:"",
		    id:state.id,
		    
		  })
		  const labelValue = (data.data.labels !== null) ? JSON.parse(data.data.labels) : [];
		  let text = labelValue.join(',');
		 
      contactCount(text)
       
      loadUrl(data.data);
      
  }

  const loadUrl = async (rs) => {
  	const longUrl = `https://wooshstories.com/story/${tenentData.domain}/${rs?.story_id}/index.html`
  	const postData = {

  		
		    "domain": "bit.ly",
		    "long_url": longUrl
  	}

  	const data =  fetch('https://api-ssl.bitly.com/v4/shorten', {
		    method: 'POST',
		    headers: {
		        'Authorization': 'Bearer aecb6a688640d728e9d1766f1b116f1f33c5b2c8',
		        'Content-Type': 'application/json'
		    },
		    body: JSON.stringify(postData)
		}).then(response => response.json())
  		.then(data => {

  				setShortLink(data.link)
  				setLongLink(longUrl)
  		});

  	 
  	
  }


	

	

  const FormSchema = Yup.object().shape({
	     // message: Yup.string()
	     //      .required('Required')
	    // labels: Yup.string()
	    //       .required('Required'),
	    
	    
	     
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		   
		   data.message = data.message;
		   data.story_url = shortLink;
	       
	     if(data.schdule_type == 'N'){
	     	 const CurrentDate = moment();
	     	 const cDate = moment(CurrentDate).format('YYYY-MM-DD HH:mm')
	     	 data.schedule = cDate
	     }
		   
	     const url = `${backendUrl}/api/broadcast/update`;
  		 await fetchWrapper.post(url,data)
  		 toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been updated.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
  		 //navigate("/broadcast")
  		 
	}
	const meChange = (val) => {
			// setSchTypeVal(val)
			// const CurrentDate = moment();
			// if(val == 'N'){
			// 	const cDate = moment.tz(CurrentDate, "Asia/Karachi").format('YYYY-MM-DD HH:mm')
			// 	const newData = formData
			// 	newData.schedule = cDate
				
				

			// 	setFormData({
		  //     ...formData,
		  //     schedule: cDate
		  //   });
			// }
		
	}
	const formatMessage = (msg) => {

			let msgValue = msg;
			if(msg){

				msgValue = msgValue.replaceAll('\n','<br>')
				msgValue = msgValue.replace('{storyurl}',shortLink)
			}
			return msgValue;

	}

	const labelChange = (labelValue) => {
			contactCount(labelValue)
			
	}


	const options = {

    chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%'
    },

    title: {
        text: `${audiance.target_contact} out of ${audiance.total_contact} Contacts`
    },

    pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
    },

    // the value axis
    yAxis: {
        min: 0,
        max: audiance.total_contact,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#000',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
            distance: 20,
            style: {
                fontSize: '14px'
            }
        },
        lineWidth: 0,
        plotBands: [{
            from: 0,
            to: 120,
            color: '#0A83FF', // green
            thickness: 80
        }]
    },

    series: [{
        name: 'Speed',
        data: [audiance.target_contact],
        tooltip: {
            valueSuffix: ' km/h'
        },
        dataLabels: {
            format: '{y} of Opportunities',
            borderWidth: 0,
            color: (
                Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || '#333333',
            style: {
                fontSize: '16px'
            }
        },
        dial: {
            radius: '100%',
            backgroundColor: 'black',
            baseWidth: 20,
            baseLength: '0%',
            rearLength: '0%'
        },
        pivot: {
            backgroundColor: 'gray',
            radius: 9
        }

    }]

};

	return(
		<>
			<div className="broad_cast_page">
				<Box px="45px" py="20px">
					<Heading fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000" mb="25px">Broadcast Story</Heading>

					<HStack alignItems="top">

				

						<Box w="70%" borderStyle="solid" borderWidth="1px" borderColor="#ccc" backgroundColor="#fff" p="20px" borderRadius="10px">
							<Text>Select Customers</Text>


								<Formik
           								enableReinitialize
							            validationSchema={FormSchema}
							            initialValues={formData}
							            onSubmit={onSubmit} > 


							  {({ values }) => (
								
								
								
               		 <Form className="needs-validation" noValidate="">

							<HStack alignItems="top" justifyContent="space-between">
								<Box w="60%">



								
										<Field
								    name="labels"
								    id="labels"
								    placeholder="Select Labels"
								    title="Labels"
								    isMulti={true}
								    component={MultiSelectFieldLabel}
								    labelChange={labelChange}
								    options={

								    	tagList?.map(item => {

								    		return {label:item.name,value:item.id}
								    	})
								    }
								/>
								


										<TextAreaField  name="message" 
										rows="5" title="SMS Message" character="100 / 160 characters" />

										<InputText name="story_url" title="Story URL" />

										<HStack justifyContent="space-between" mb="20px">
											<Box>
												<Text mb="0px" fontSize="12px" fontWeight="400" fontFamily="'Montserrat', sans-serif" textColor="#A9A9A9" fontStyle="italic">Use a short url to save character space in your SMS</Text>
											</Box>

											<Box>
												
											</Box>
										</HStack>

										<Box mb="20px">


											<RadioComponent 
												name="schdule_type"
												title="Schedule Broadcast"
											  list={[{id:'N',value:'Send Now'},{id:'L',value:'Schedule for Later'}]} 
											  meChange={meChange}
											  />

										</Box>
										
									{values.schdule_type == 'L' &&
										<Box>
											<Text fontSize="12px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000" mb="7px">Send on</Text>

											<HStack>
												<Box>
												
													<InputDateField inputProps={{type:'datetime-local'}} name="schedule" />
												
												</Box>

											

												
											</HStack>
										</Box>
											}
										<Box>
											<Button 
											type="submit"
											borderWidth="0px" 
											borderRadius="5px" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" backgroundColor="#0A83FF" color="#fff" px="50px" py="12px" mt="20px">Schedule Broadcast</Button>
										</Box>
									
								</Box>

								<Box w="35%">
									<Box >
									

								 <HighchartsReact
          highcharts={Highcharts}
          options={options}
          
        />
									
										
										<Text textAlign="center" mb="0" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000"></Text>
									</Box>
									
									<Box>

									<Box>
										<b>Message</b>

											<Box borderWidth="1px" height="150" padding="2"  > 
												 
												 <div style={{overflow:'auto',height:125,borderWidth:0}} dangerouslySetInnerHTML={{ __html: formatMessage(values.message)}} ></div>
											</Box>
									</Box>
										
									</Box>
								</Box>
							</HStack>
							</Form>
      )}
    </Formik>
						</Box>

						

						<Box ml="20px" w="30%" borderStyle="solid" borderWidth="1px" borderColor="#ccc" backgroundColor="#fff" p="20px" borderRadius="10px">
							<Text mb="20px" fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" color="#000000">Story</Text>

							

							<div className="story_card" >

                  <div style={{widht:'300px',height:'472px'}}>

                    {rs.Story?.status_id == 10 ? <iframe src={`${backendUrl}/story/${rs.story_id}/index.html`} width="100%" height="472" frameborder="0"></iframe> : 


                    null

                  }
                      
                    
                  </div>
                  </div>

						</Box>
					</HStack>
				</Box>
			</div>
		</>
	)
}

export default BroadCastDetail;