import { ChakraProvider } from '@chakra-ui/react'

const DomainError = () => {


	return (

			
				<div>Domain not found</div>
			
		)
}

export default DomainError;