import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import masterCard from '../../assets/images/mastercard.png';
import download from '../../assets/images/download.png';
import Table from 'react-bootstrap/Table';

function Billing(){
	return(
		<>
			


							
								<h4 className="inner_main_heading">Billing</h4>

								<div className="plan_section">
									<h2>Your Plan</h2>

									<div className="d-flex justify-content-between align-items-end mb-20">
										<div className="text_section">
											<h3>Plan Name Goes Here</h3>
											<span>Plan details whats included and whats not</span>
										</div>

										<div>
											<p className="amount_section"><span>USD</span> $49.00 <span>/mo</span></p>
										</div>
									</div>

									<div className="d-flex justify-content-between">
										<div className="text_section">
											<h3>Billing Date</h3>
										</div>

										<div>
											<p>10th of every month</p>
										</div>
									</div>
								</div>

								<div className="plan_section">
									<h2>Payment Method</h2>

									<div className="d-flex justify-content-between mb-20">
										<div className="text_section">
											<div className="d-flex mb-5">
												<h3>Payment method</h3>
												<div className="badge ml-15">Primary</div>
											</div>

											<div className="d-flex">
												<Image
													className="me-10"
									              	src={masterCard}
									            />
												<span>Mastercard ending in 1109</span>
											</div>
										</div>

										<div>
											<a href="#">Change Payment Method</a>
										</div>
									</div>

									<Button className="primary">Add Payment Method</Button>
								</div>

								<div className="invoices_section">
									<h2>Invoices</h2>

									<Table className="table_section">
										<tbody>
									        <tr>
									          <td colSpan={4}>
									          	<h4>Subscription</h4>
									          </td>
									          <td colSpan={3}>
									          	<p>Created Mar 4, 2023</p>
										      </td>
									          <td colSpan={2}>
									          	<div className="d-flex align-center justify-content-end">
									          		<div className="badge me-20">Paid</div>
									          		<div className="me-20">
									          			<p>$ 49.00 USD</p>
									          		</div>
									          		<a href="#" className="d-flex align-center">
										          		INV-10001 
										          		<Image
															className="me-10"
											              	src={download}
											            />
									            	</a>
									          	</div>
									          </td>
									        </tr>

									        <tr>
									          <td colSpan={4}>
									          	<h4>Subscription</h4>
									          </td>
									          <td colSpan={3}>
									          	<p>Created Mar 4, 2023</p>
										      </td>
									          <td colSpan={2}>
									          	<div className="d-flex align-center justify-content-end">
									          		<div className="badge me-20">Paid</div>
									          		<div className="me-20">
									          			<p>$ 49.00 USD</p>
									          		</div>
									          		<a href="#" className="d-flex align-center">
										          		INV-10001 
										          		<Image
															className="me-10"
											              	src={download}
											            />
									            	</a>
									          	</div>
									          </td>
									        </tr>

									        <tr>
									          <td colSpan={4}>
									          	<h4>Subscription</h4>
									          </td>
									          <td colSpan={3}>
									          	<p>Created Mar 4, 2023</p>
										      </td>
									          <td colSpan={2}>
									          	<div className="d-flex align-center justify-content-end">
									          		<div className="badge me-20">Paid</div>
									          		<div className="me-20">
									          			<p>$ 49.00 USD</p>
									          		</div>
									          		<a href="#" className="d-flex align-center">
										          		INV-10001 
										          		<Image
															className="me-10"
											              	src={download}
											            />
									            	</a>
									          	</div>
									          </td>
									        </tr>

									        <tr>
									          <td colSpan={4}>
									          	<h4>Subscription</h4>
									          </td>
									          <td colSpan={3}>
									          	<p>Created Mar 4, 2023</p>
										      </td>
									          <td colSpan={2}>
									          	<div className="d-flex align-center justify-content-end">
									          		<div className="badge me-20">Paid</div>
									          		<div className="me-20">
									          			<p>$ 49.00 USD</p>
									          		</div>
									          		<a href="#" className="d-flex align-center">
										          		INV-10001 
										          		<Image
															className="me-10"
											              	src={download}
											            />
									            	</a>
									          	</div>
									          </td>
									        </tr>
									    </tbody>
									</Table>

									<a href="#">View all billing history</a>
								</div>
							
						
		</>
	)
}

export default Billing;