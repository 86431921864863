import { fetchWrapper } from '../helpers';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import dots from '../../assets/images/dots.png';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import {Link,useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import { useToast } from '@chakra-ui/react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'

function List({list,show,loadModel,loadList}){

  const toast = useToast()

  const navigate = useNavigate();
  const backendUrl =   process.env.REACT_APP_API_URL

   const setting = (rs) => {
      navigate('/broadcastdetail',{state:{ id: rs.id }})

  }

   const settingView = (rs) => {
      navigate('/broadcastdetailview',{state:{ id: rs.id }})

  }
  const inital = (name) => {

      return name.substr(0,1).toUpperCase();
  }

  const anyalitic = async(rs) => {
    navigate('/broadcastanalytic',{state:{ id: rs.id,labels:rs.labels,story_id:rs.story_id }})
    
  }

 
    return (
      <div className="contact_page">
            <div className="head_section">
               <h1 className="main_heading">Broadcast Story</h1>

               <div>
                  <Button className="primary" onClick={() => loadModel('create')}>Create New Broadcast</Button>
               </div>
            </div>

            <div className="page_data">
               <Table className="table_section">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Story</th>
                      <th>Broadcast via</th>
                      <th>Audience Size</th>
                      <th>Broadcast Date & Time</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                     list?.map((rs,index) => {


                           return (



                           <tr key={index}>
                      <td>{rs.id}</td>
                      <td>{rs.Story.name}</td>
                      <td>{rs.BroadCastType.name}</td>
                      <td>{rs.audience}</td>
                      <td>{(rs.schedule) ? moment(rs.schedule).format('DD MMMM YYYY hh:mm A') : ' Not Set'}</td>
                      <td>{(rs.status_id === 10) ? 'Sent' : 'Pending'}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle className="dropdown_link" variant="success" id="dropdown-basic">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                           
                          
                            <Dropdown.Item onClick={() => anyalitic(rs)}>Broadcast Analytics
</Dropdown.Item>
                            {rs.status_id == 10 ?

                                (<Dropdown.Item onClick={() => settingView(rs)}>View</Dropdown.Item>)

                                :
                                (<Dropdown.Item onClick={() => setting(rs)}>Settings</Dropdown.Item>)
                             }
                            
                            
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      
                    </tr>


                           )
                     })
                  }
                       
                  </tbody>
                </Table>
            </div>
         </div>
    );
  
}

export default List;
