import { ChakraProvider } from '@chakra-ui/react'

const Error = () => {


	return (

			
				<div className="analytic_page">
	        		<div className="d-flex mb-20 justify-content-between">
	          			<h1 className="main_heading">Sorry</h1>
	          			
	          		</div>
	          		<div>Your plan not subscribe successfully</div>
	          	</div>
          	
		)
}

export default Error;