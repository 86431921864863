import React from 'react';

import {  Text } from '@chakra-ui/react'
import {
  
  TextareaControl,
  
} from "formik-chakra-ui";

function TextAreaField(props) {
	const {name,title,character} = props
	const [value, setValue] = React.useState('')
	const handleChange = (event) => setValue(event.target.value)
	return(
		<>
			<div className="field_section">
				<Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000" mb="7px">{title}</Text>
				
				<TextareaControl name={name}   />
				<Text fontSize="12px" fontWeight="400" fontFamily="'Montserrat', sans-serif" textColor="#A9A9A9" fontStyle="italic">{character}</Text>
			</div>
			
		</>
	);
}

export default TextAreaField;