import React,{useState,useEffect} from 'react';
import CreatableSelect from 'react-select/creatable';
const MultiSelectFieldCreate = ({
    field,
    form,
    options,
    isMulti = false,
    title,
    placeholder = 'Select'
}) => {
    

     const [newOption,setNewOption] = useState(options)
    
     useEffect(() => {
        setNewOption(options)
       
    }, [options]);
    const meChange = (option) => {
        //form.setFieldValue(field.name, option.value)

        form.setFieldValue(
            field.name,
            option ? (option).map((item) => item.value) : [],
        );

        const num = option.find((opVal) => opVal?.__isNew__ === true)
       
        // //option?.__isNew__?.indexOf(option?.__isNew__)
        if(num){
            setNewOption([...newOption,{label:num.label,value:num.value}])
        }
        
    }


    const getValue = () => {
        if (options) {
          
            console.log(newOption)

            return isMulti
                ? newOption.filter((option) => field?.value?.indexOf(option?.value) >= 0)
                : newOption.find((option) => option?.value === field.value);
        } else {
            return isMulti ? [] : ('');
        }
    };

  
    
        return (

        	<div className="field_section">
				
						<label>{title}</label>
						
						


            <CreatableSelect isMulti options={options} 

                name={field.name}
               value={getValue()}
                onChange={(newValue) => meChange(newValue)}
                onBlur={field.onBlur}
                placeholder={placeholder}
            />
				          
				           
				          
					</div>
            
        )
   
}

export default MultiSelectFieldCreate;