import React,{useEffect,useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { fetchWrapper } from '../helpers';
import { useToast } from '@chakra-ui/react'
function Integration(){


const toast = useToast()
	const [listData,setListData] = useState({})
	const [show,setShow] = useState(false)
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListData()
       
       
        
    }, [show]);


   const loadListData = async () => {
      const url = `${backendUrl}/tenent/by-id?id=1`;
      const data  = await fetchWrapper.get(url);

      if(data.data.settings != ''){

      	const settingsData =  JSON.parse(data.data.settings);
      	if(settingsData['integration']){


	      		setListData(settingsData['integration'])

	      	setForm({sid:listData.sid,authToken:listData.authToken,number:listData.number
	    		})

      	}
      	

      }

      
      

      
     
      setShow(true)

  }

	const [form, setForm] = useState({
	    sid: "",
	    authToken:"",
	    number:"",
	    
   
  	});


  	const handleChange = (e) => {

    	setForm({ ...form, [e.target.name]: e.target.value });
  }

  const submitButton = async (e) => {
    e.preventDefault();
   
    const data = {id:1,settings:form,type:'integration'};
    const url = `${backendUrl}/tenent/save-settings`;
  	await fetchWrapper.post(url,data)


  	toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record have been uploaded.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
    
    
  }

	return(
		<>

							
								<h4 className="inner_main_heading">Integrations</h4>
								<h2 className="inner_main_heading">Twillo</h2>
								<Form className="d-flex">
									<div className="me-20 w-30">
									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>SID</Form.Label>
									        <Form.Control 
									        name="sid"  value={form.sid} onChange={ (e) => handleChange(e)}
									        type="name" />
									      </Form.Group>

									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>From Number</Form.Label>
									        <Form.Control 
									        name="number"  value={form.number} onChange={ (e) => handleChange(e)}
									        type="name" />
									      </Form.Group>

									      

								      	<Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Save</Button>
									</div>

									<div className="w-30">
										<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Auth Token</Form.Label>
									        <Form.Control 
									         name="authToken"  value={form.authToken} onChange={ (e) => handleChange(e)}
									        type="name" />
									      </Form.Group>

										
									</div>
								</Form> 
							
						
		</>
	)
}

export default Integration;