import React,{useEffect,useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { fetchWrapper } from '../helpers';
import SelectFieldChakra from '../FormElement/SelectFieldChakra'
import { useToast } from '@chakra-ui/react'
function Seo(){

	
const toast = useToast()

	const [listData,setListData] = useState({})
 	const [authorTypeList,setAuthorTypeList] = useState([]);
	const [storyLanguageList,setStoryLanguageList] = useState([]);
	const [show,setShow] = useState(false)

  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListData()
         loadAuthorTypeList()
       	loadStoryLanguageList()
       
        
    }, [show]);

   	const loadAuthorTypeList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/author_type/list`;
      const data  = await fetchWrapper.get(url)
      setAuthorTypeList(data.data)
  }

  const loadStoryLanguageList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/language/list`;
      const data  = await fetchWrapper.get(url)
      setStoryLanguageList(data.data)
  }

   const loadListData = async () => {
      const url = `${backendUrl}/tenent/by-id?id=1`;
      const data  = await fetchWrapper.get(url);
      if(data.data.settings != ''){
      	const settingsData =  JSON.parse(data.data.settings);
      	setListData(settingsData['seo'])
      }
      

      
      setForm({author_name:listData.author_name,author_type:listData.author_type,publisher_name:listData.publisher_name,language_id:listData.language_id})
      setShow(true)

  }

	const [form, setForm] = useState({
	    author_name: "",
	    author_type:"",
	    publisher_name:"",
	    language_id:"",
   
  	});


  	const handleChange = (e) => {

    	setForm({ ...form, [e.target.name]: e.target.value });
  }

  const submitButton = async (e) => {
    e.preventDefault();
   
    const data = {id:1,settings:form,type:'seo'};
    const url = `${backendUrl}/tenent/save-settings`;
  	await fetchWrapper.post(url,data)
    
    toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been updated.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
  }

	return(
		<>

							
								<h4 className="inner_main_heading">General SEO Settings</h4>

								<Form className="d-flex">
									<div className="me-20 w-30">
									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Author Name</Form.Label>
									        <Form.Control 
									        name="author_name"  value={form.author_name} onChange={ (e) => handleChange(e)}
									        type="name" />
									      </Form.Group>

									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Author Type</Form.Label>
											<Form.Select aria-label="Default select example"

												 name="author_type"  value={form.author_type} onChange={ (e) => handleChange(e)}
											>
										      	<option>Select Author</option>
										      	{authorTypeList?.rows?.map(rsAuthor => {

										      		return (<option value={rsAuthor.id}>{rsAuthor.name}</option>)
										      	})}
										      
								    		</Form.Select>
							    		</Form.Group>

								      	<Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Save</Button>
									</div>

									<div className="w-30">
										<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Publisher Name</Form.Label>
									        <Form.Control 
									         name="publisher_name"  value={form.publisher_name} onChange={ (e) => handleChange(e)}
									        type="name" />
									      </Form.Group>

										<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Story Language</Form.Label>
											<Form.Select aria-label="Default select example"

											 name="language_id" value={form.language_id} onChange={ (e) => handleChange(e)}
											>
										      	<option>Select Language</option>
										      	{storyLanguageList?.rows?.map(rsAuthor => {

										      		return (<option value={rsAuthor.id}>{rsAuthor.name}</option>)
										      	})}
										      	
								    		</Form.Select>
							    		</Form.Group>
									</div>
								</Form> 
							
						
		</>
	)
}

export default Seo;