import React,{useEffect,useState} from 'react';
import Modal from 'react-bootstrap/Modal';


function Model({children,show,handleClose,title,size,centered}){


	



	return(

		<Modal size={size ? size : ""} centered={centered ? centered : ""}  show={show} onHide={handleClose}>
							        <Modal.Header closeButton>
							          <Modal.Title>{title}</Modal.Title>
							        </Modal.Header>
							        <Modal.Body>
							        	
							        	{children}
							       	</Modal.Body>
		        
		      					</Modal>

		)
}

export default Model