import React,{useEffect,useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import {InputText,SelectField} from '../../components/FormElement'
import Form from 'react-bootstrap/Form';
import { fetchWrapper } from '../helpers';
import axios from 'axios';



function ImportContact({loadList,handleClose}){
	
	
	const [selectedFiles,setSelectedFiles] = useState({})

	const backendUrl = process.env.REACT_APP_API_URL

	


	const submitButton = async (e) => {
    e.preventDefault();
   
    
    		
    		let fd = new FormData();
    		fd.append("file", selectedFiles);
		    
		   

		    axios
		      .post( backendUrl + '/contact/import-contact', fd)
		      .then(rs  => {


		      		loadList()
		      		handleClose();
		      }
		      		

		       );
    
    
  }

	const handleChangeLogo = (e) => {


		  	 
		  	 setSelectedFiles(e.target.files[0])
		    

  	 
  }


	return (

			<>
			<div className="create_user_form">
				<Form
			            
			 	>


			 	
			 	

			 	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
										        <Form.Label>Upload File</Form.Label>
										        <Form.Control name="logo" onChange={ (e) => handleChangeLogo(e)} type="file" />
								    		</Form.Group>
			 	


		         <Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Upload</Button>

			</Form>
			</div>
			</>

	)
}

export default ImportContact;