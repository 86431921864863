import React,{useEffect,useState} from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import List from './contact/List'
import Create from './contact/Create'
import Edit from './contact/Edit'
import ImportContact from './contact/ImportContact'
import { fetchWrapper } from './helpers';
import {Model} from '../components/FormElement'
import { ChakraProvider } from '@chakra-ui/react'
function ContactPage(){
	const [show, setShow] = useState(false);
	const [modelType, setModelType] = useState('create');
   const [rs, setRs] = useState(0);  

	const [listData,setListData] = useState([])
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadList()
       
       
        
    }, [show]);


   const loadList = async () => {
      const url = `${backendUrl}/contact/list`;
      const data  = await fetchWrapper.get(url)
      setListData(data.data)
  }


	const handleClose = () => setShow(false);
  const handleShow = (user_type_id) => {
  	setModelType('create')
  		setUserTypeId(user_type_id)	
  		setShow(true)
  }

  const loadModel = (type,rs=0) => {
  	 setRs(rs)
  	 setModelType(type)
  	 setShow(true)
  }

	return(
		<>
	
			<List loadModel={loadModel} list={listData} loadList={loadList} show={show} />

			{
				modelType == 'create' ? 
				<Model title="Add Contact" handleClose={handleClose} show={show} 
				children={<Create loadList={loadList} handleClose={handleClose}   />} /> : null}



			{
				modelType == 'edit' ? 
				<Model title="Edit Contact" handleClose={handleClose} show={show} 
				children={<Edit loadList={loadList} rs={rs} handleClose={handleClose}   />} /> : null}



			{
				modelType == 'import' ? 
				<Model title="Import Contact" handleClose={handleClose} show={show} 
				children={<ImportContact loadList={loadList}  handleClose={handleClose}   />} /> : null}

			
		</>
	)
}

export default ContactPage;