import Select from 'react-select';

const MultiSelectFieldLable = ({
    field,
    form,
    options,
    isMulti = false,
    title,
    placeholder = 'Select',
    labelChange
}) => {
    function onChange(option) {
        form.setFieldValue(
            field.name,
            option ? (option).map((item) => item.value) : [],
        );
        const labelValue = option ? (option).map((item) => item.value) : [];
        
        labelChange(labelValue);
    }

    const getValue = () => {
        if (options) {
            
            return isMulti
                ? options.filter((option) => field?.value?.indexOf(option?.value) >= 0)
                : options.find((option) => option?.value === field.value);
        } else {
            return isMulti ? [] : ('');
        }
    };

    if (!isMulti) {
        return (

        	<div className="field_section">
				
						<label>{title}</label>
						
						<Select
                options={options}
                name={field.name}
                value={options ? options.find(option => option.value === field.value) : ''}
                onChange={(option) => form.setFieldValue(field.name, option.value)}
                onBlur={field.onBlur}
                placeholder={placeholder}
            />
				          
				           
				          
					</div>
            
        )
    } else {
        return (

        <div className="field_section">
				
						<label>{title}</label>
						
						<Select
                className="react-select-container"
                classNamePrefix="react-select"
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={options}
                isMulti={true}
                placeholder={placeholder}
            />
				          
				           
				          
					</div>
            
        )
    }
}

export default MultiSelectFieldLable;