/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { __ } from '@googleforcreators/i18n';
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
} from '@googleforcreators/design-system';
import { useCallback } from 'react';
import { useStory, useHistory, Tooltip } from '@googleforcreators/story-editor';
import styled from 'styled-components';
import {useNavigate } from 'react-router-dom';
/**
 * Internal dependencies
 */



import arrowLeft from './inline-icons/arrow-left.svg';

const Space = styled.div`
  width: 8px;
`;
const ArrowLeft = styled.img`
  width: 32px;
  height: 32px;

  left: -1px;
  top: -1px;
  src: url('${arrowLeft}');
  background-size: 100%;
  /* The icon is black and we can't use color because it's not inline so invert it is */
`;

const ButtonContainer = styled.div`
  margin: 0px;
`;



function LeftButton() {
  const navigate = useNavigate();
  const label = __('Left', 'web-stories');

  return (
    <>
      
      <Tooltip title={label} hasTail>
        <ButtonContainer>
          <Button    
            aria-label={label}
            onClick={ () => navigate('/dashboard')}
          >
            <ArrowLeft
              src={arrowLeft}
              alt={__('Button to reset the editor.', 'web-stories')}
            />
          </Button>
        </ButtonContainer>
      </Tooltip>
      <Space />
    </>
  );
}

export default LeftButton;
