import React,{useState,useEffect} from 'react';

const Success = () => {


	return (
			<>

			<div className="analytic_page">
        		<div className="d-flex mb-20 justify-content-between">
          			<h1 className="main_heading">Thank You</h1>
          			
          		</div>
          		<div>Your plan has been subscribe successfully</div>
          	</div>
          	
			
			
			</>
		)
}

export default Success;