import React,{useEffect,useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../components/FormElement'

import { fetchWrapper } from '../helpers';




function ChangePassword({loadList,user_type_id,handleClose,rs}){
	


	const backendUrl = process.env.REACT_APP_API_URL

	const [formData, setFormData] = useState({
   
    password:'',
    id:rs.id,
    
  });

  const FormSchema = Yup.object().shape({
	    
	    password: Yup.string()
	          .required('Required')
	     
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${backendUrl}/user/change-password`;
  		 await fetchWrapper.post(url,data)
  		 loadList();
  		 handleClose();
	}

	


	return (

			<>
			<div className="create_user_form">
				<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 	>


			 	

			 	<InputText name="password" inputProps={{ type: "password" }} title="Password"  />
			 	


		         <Button
				            mt={4}
				            colorScheme='teal'
				            className="primary"
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>
			</div>
			</>

	)
}

export default ChangePassword;