import React,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import Table from 'react-bootstrap/Table';
import { HStack, Box, Heading, Text, Button } from '@chakra-ui/react'
import {InputText,Form,TextAreaField,SelectField,RadioComponent,InputDateField} from './FormElement'
import { Link } from '@chakra-ui/react'
import { fetchWrapper } from './helpers';
import { Image } from '@chakra-ui/react'
import rightbar from '../assets/images/rightbar.png'
import {useLocation } from 'react-router-dom';
import { Progress } from '@chakra-ui/react'
import {
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
} from '@chakra-ui/react'

function BroadCastAnalytic(){
		let { state } = useLocation();
		const lables = JSON.parse(state.labels)
		const [list,setList] = useState([])
		const [tagList,setTagList] = useState([])
		const [per,setPer] = useState(0)
		const [cost,setCost] = useState(0)
		const backendUrl = process.env.REACT_APP_API_URL

		useEffect(() => {
      
        loadData()
        loadTagData()
      	
       
       
        
    }, [state.id]);


const loadTagData = async () => {
      const url = `${backendUrl}/api/customer-tags/list`;
      
       const data  = await fetchWrapper.get(url)
       setTagList(data.data)
      
     

  	}

const loadData = async () => {
      const url = `${backendUrl}/api/broadcast-log/list?id=${state.id}`;
      
       const data  = await fetchWrapper.get(url)
       setList(data.data)
       let del = 0 
       let costVal = 0;
       data?.data?.map(rs => {
       		 if(rs.status_id == 10){
       		 		del = del + 1;
       		 		costVal = costVal + rs.cost
       		 }

       })
       const perValue = (del / data.data.length * 100);
       setPer(perValue)
       setCost(costVal)
     

  	}

	return(
		<>
			<div className="broad_cast_page">
				<Box px="45px" py="20px">
					<Heading fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000" mb="25px">Broadcast Analytics</Heading>

					<HStack alignItems="top">
						<Box w="70%" borderStyle="solid" borderWidth="1px" borderColor="#ccc" backgroundColor="#fff" p="20px" borderRadius="10px">
							<Table className="table_section">
                <thead>
                  <tr>
                    <th>Customer Name</th>
                    <th>Contact #</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                {
                	
                	list?.map(rs => {

                		 
                		return (

                				<tr>
		                		<td>{rs.Contact.name}</td>
		                		<td>{rs.Contact.phone}</td>
		                		<td>{rs.status_id == 10 ? 'Delivered' : rs.status_id == 1 ? 'Pending' : 'Api Error'}</td>
		                	</tr>


                			)
                })}
                	
                	
                </tbody>
              </Table>
						</Box>

						<Box ml="20px" w="30%" borderStyle="solid" borderWidth="1px" borderColor="#ccc" backgroundColor="#fff" p="20px" borderRadius="10px">
							<Text mb="20px" fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" color="#000000">Broadcast Details</Text>

							<Box mb="20px" borderBottomWidth="1px" borderColor="#ccc" borderStyle="solid">
								<Text mb="10px" fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" color="#000000">Successful Delivery</Text>

								<Progress mb="20px" borderWidth="1px" borderStyle="solid" borderColor="#ccc" backgroundColor="#f5f5f5" height='32px' value={per} />

								<Text mb="15px" fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" color="#000000">Total Cost: $ {cost}</Text>
							</Box>

							<Box>
								<Text mb="10px" fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" color="#000000">Audience</Text>

								<HStack>
								{

							    	
							     lables?.map(item => {

							     			return (



							     				<Tag
							      size='lg'
							      borderRadius='full'
							      variant='solid'
							      borderWidth="1px"
							      borderColor="#363B78"
							      backgroundColor="#f5f5f5"
							      color="#000"
							      font-size="14px"
							      fontWeight="400"
							      fontFamily="'Montserrat', sans-serif"
							    >

							      <TagLabel>{tagList.filter(rs => rs.id == item)[0]?.name}</TagLabel>
							      <TagCloseButton />
							    </Tag>


							     			)

							     })
									

							    }
								</HStack>
							</Box>
							
							
						</Box>

						<Box ml="20px" w="30%" borderStyle="solid" borderWidth="1px" borderColor="#ccc" backgroundColor="#fff" p="20px" borderRadius="10px">
							<Text mb="20px" fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" color="#000000">Story</Text>

							<iframe src={`${backendUrl}/story/${state.story_id}/index.html`} width="100%" height="472" frameborder="0"></iframe>

						</Box>
					</HStack>
				</Box>
			</div>
		</>
	)
}

export default BroadCastAnalytic;