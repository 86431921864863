import React,{useEffect,useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import {InputText,Form,SelectField,MultiSelectField} from '../../components/FormElement'
import { useToast } from '@chakra-ui/react'

import {
   
    Field,
    
} from 'formik';

import { fetchWrapper } from '../helpers';




function Edit({loadList,handleClose,rs}){
	

	const toast = useToast()
	const backendUrl = process.env.REACT_APP_API_URL

	const [tagList,setTagList] = useState([])
	


	const [formData, setFormData] = useState({
    name: rs.name,
   
  
    
    
  });

  const FormSchema = Yup.object().shape({
	     name: Yup.string()
	          .required('Required'),
	  
	   
	    
	     
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${backendUrl}/api/customer-tags/update`;
  		 await fetchWrapper.post(url,data)
  		 loadList();


  		 toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been updated.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })

  		 handleClose();
	}

	


	return (

			<>
			<div className="create_user_form">
				<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 	>


			 	<InputText name="name" title="Name"  />
			 


		         <Button
				            mt={4}
				            colorScheme='teal'
				            className="primary"
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>
			</div>
			</>

	)
}

export default Edit;