import React,{useEffect,useState} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { fetchWrapper } from './helpers';
import {InputText,Form,SelectField,InputTextLogin} from '../components/FormElement'
import * as Yup from 'yup';
import { ChakraProvider } from '@chakra-ui/react'



import loginlogo from '../images/login_logo.png';


function Login(){
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_API_URL
  const [ showError, setShowError ] = useState(false);


  const [formData, setFormData] = useState({
    username: '',
    password: '',
   
    
  });
   const FormSchema = Yup.object().shape({
      
      username: Yup.string()
            .required('Required'),
     
      password: Yup.string()
            .required('Required')
       
  });


   const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${backendUrl}/auth/signin`;
       await fetchWrapper.post(url,data).then(res => {
                localStorage.setItem('user', JSON.stringify(res.accessToken));
                
                navigate('/dashboard');

       })

      .catch((err) => setShowError(true))        
       
  }


  const dashboard = () => {
      navigate('/dashboard')
  }

    return (

      
      <Form
                  enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit={onSubmit}
       >

      <div className="login_page">
      <div className="login_box">
        <div className="form_section">
          <img src={loginlogo} alt={loginlogo} />

          <div className="text_section">
            {/*<h3>Story Builder</h3>*/}
          
            <p>Please login into your account</p>
          </div>
         
         
            <InputTextLogin name="username" type="text" title=""  />
          

          
            
            <InputTextLogin name="password" inputProps={{ type: "password" }} title=""  />
        

          <button className="login_button"  type="submit">Login</button>

          <a href="#" className="forgot_link">Forgot Password?</a>

          {showError ? (
                <div style={{color:'red'}}>
                    User ID or password incorrect
                </div>
            ) : null}
        </div>
      </div>

      <p>© 2023, Woosh Stories. All Rights Reserved</p>
    </div>
    </Form>
 
    );
  }


export default Login;
