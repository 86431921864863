import React from 'react';
import { Radio } from '@chakra-ui/react'
import {
  RadioGroupControl
} from "formik-chakra-ui";


function RadioComponent(props) {
	const {name,title,list,inputValue} = props
	const [value, setValue] = React.useState('')
	const handleChange = (event) => {
		
			setValue(event.target.value)
			props.meChange(event.target.value)
	}
	return(
		<>	
			<RadioGroupControl name={name} onChange={handleChange} label={title}>
			{list.map(item => {

						return (<Radio value={item.id}>{item.value}</Radio>)

			})}
           
          </RadioGroupControl>
		</>
	);
}

export default RadioComponent;