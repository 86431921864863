import React,{useEffect,useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import rightBar from '../images/rightbar.png';
import LeftNav from './settings/leftnav';
import dots from '../assets/images/dots.png';
import User from './User'
import Analytic from './settings/Analytic'
import Branding from './settings/Branding'
import Typography from './settings/Typography'
import Billing from './settings/Billing'
import Seo from './settings/Seo'
import Integration from './settings/Integration'
import CustomerTag from './CustomerTag'
import Mobile from './settings/Mobile'

function StoryPublishSetting(){
	 const [ getPage, setGetPage ] = useState(false);


	
	 useEffect(() => {
        loadPage('seo')
       
       
        
    }, []);

	const loadPage = (page_id) => {
		
		if(page_id == 'user'){
			setGetPage(<User />)
		}

		if(page_id == 'analytic'){
			setGetPage(<Analytic />)
		}

		if(page_id == 'branding'){
			setGetPage(<Branding />)
		}

		if(page_id == 'typography'){
			setGetPage(<Typography />)
		}

		if(page_id == 'billing'){
			setGetPage(<Billing />)
		}


		if(page_id == 'seo'){
			setGetPage(<Seo />)
		}

		if(page_id == 'integration'){
			setGetPage(<Integration />)
		}

		if(page_id == 'tags'){
			setGetPage(<CustomerTag />)
		}
		if(page_id == 'mobile'){
			setGetPage(<Mobile />)
		}
	} 

	return(
		<>



		<div className="setting_page">
				<div className="container">
					<div className="mb-20">
						<h1 className="main_heading">Settings</h1>
					</div>

					<div className="setting_inner_section">
						<div className="w-25 me-20">
							<LeftNav loadPage={loadPage} />
						</div>

						<div className="w-75">
							<div className="page_data">

								{getPage}
							</div>
						</div>
					</div>
				</div>	
			</div>
			

			
		</>
	);
}

export default StoryPublishSetting;