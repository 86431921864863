import React,{useEffect,useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { fetchWrapper } from '../helpers';
import { useToast } from '@chakra-ui/react'

function Analytic(){
const toast = useToast()
	const [listData,setListData] = useState({})
	const [show,setShow] = useState(false)
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListData()
       
       
        
    }, [show]);


   const loadListData = async () => {
      const url = `${backendUrl}/tenent/by-id?id=1`;
      const data  = await fetchWrapper.get(url)

      if(data.data.settings != ''){

      	const settingsData =  JSON.parse(data.data.settings);
      	if(settingsData['analytic']){


	      		setListData(settingsData['analytic'])

	      	 setForm({google_analytic:listData.google_analytic,facebook_pixel:listData.facebook_pixel
    			})

      	}
      	

      }


      
      setShow(true)

  }

	const [form, setForm] = useState({
	    google_analytic: "",
	    facebook_pixel:"",
	   
   
  	});


  	const handleChange = (e) => {

    	setForm({ ...form, [e.target.name]: e.target.value });
  }

  const submitButton = async (e) => {
    e.preventDefault();
   
    const data = {id:1,settings:form,type:'analytic'};
    const url = `${backendUrl}/tenent/save-settings`;
  	await fetchWrapper.post(url,data)

  	toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record has been updated.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
    
    
  }

	return(
		<>
			<h4 className="inner_main_heading">Analytics</h4>

								<Form className="d-flex">
									<div className="me-20 w-30">
									      <Form.Group className="field_section" controlId="exampleForm.ControlInput1">
									        <Form.Label>Google Analytics Tracking ID</Form.Label>
									        <Form.Control 
									        name="google_analytic"  value={form.google_analytic} onChange={ (e) => handleChange(e)}
									        type="name" />
									      </Form.Group>

								      	<Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Save</Button>
									</div>

									<div className="w-30">
										
									</div>
								</Form> 
		</>
	)
}

export default Analytic;