import SaveButton from "./saveButton";
import { HeaderTitle } from '@googleforcreators/story-editor';
import styled from 'styled-components';
import LeftButton from './leftButton';
import PreviewButton from "./PreviewButton";
import { Buttons } from './buttons';
function HeaderLayout() {

  const Background = styled.header.attrs({
  role: 'group',
  'aria-label': 'Story canvas header',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  background-color: ${({ theme }) => theme.colors.bg.primary};
`;

const Head = styled.div`
  flex: 1 1 auto;
  display: flex;
  padding: 1em;
`;


const ButtonCell = styled.div`
  grid-area: buttons;
`;

  return (
    <Background>
      <Head>
        <LeftButton />
        <HeaderTitle />
        
      </Head>
      <ButtonCell>
        <Buttons />
      </ButtonCell>
    </Background>
  );
}

export default HeaderLayout;