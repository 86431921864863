import React,{useEffect,useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Plus from '../../assets/images/plus.svg';
import Cross from '../../assets/images/cross.svg';
import Image from 'react-bootstrap/Image';
import { fetchWrapper } from '../helpers';
import { useToast } from '@chakra-ui/react'
import axios from 'axios';
function Branding(){


const toast = useToast()

	const tenentData = JSON.parse(localStorage.getItem('tenent'));
	const tenent_id = tenentData.id

	const [listData,setListData] = useState({})
	const [industryList,setIndustryList] = useState([]);
	
	const [show,setShow] = useState(false)
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListData()
        loadIndustryList()
       
        
        
    }, [show]);

   const loadIndustryList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/industry/list`;
      const data  = await fetchWrapper.get(url)
      setIndustryList(data.data)
  }

   const loadListData = async () => {
      const url = `${backendUrl}/tenent/by-id?id=1`;
      const data  = await fetchWrapper.get(url);

      if(data.data.settings != ''){

      	const settingsData =  JSON.parse(data.data.settings);
      	if(settingsData['branding']){


	      		setListData(settingsData['branding'])

	      	setForm({brand_name:listData.brand_name,industry_id:listData.industry_id
	    		})

      	}
      	

      }

      
      
      setShow(true)

  }

	const [form, setForm] = useState({
	    brand_name: "",
	    industry_id:"",
	    
   
  	});


  	const handleChange = (e) => {

    	setForm({ ...form, [e.target.name]: e.target.value });
  }

   const handleChangeLogo = (e) => {


  	 let fd = new FormData();
     fd.append("logo", e.target.files[0]);
    
   

    axios
      .post( backendUrl + '/tenent/upload-logo', fd,
      		{
	      		headers: 
					   {'x-tenent':tenent_id}
					 
					}
      	)
      .then( 


      		toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Logo have been uploaded.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })

       );

  	 
  }

  const handleChangeFav = (e) => {


  	 let fd = new FormData();
    fd.append("fav", e.target.files[0]);
    
   

    axios
      .post( backendUrl + '/tenent/upload-fav', fd,

      		{
	      		headers: 
					   {'x-tenent':tenent_id}
					 
					}
					
      	)
      .then( 
      		toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Fav icon have been uploaded.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
       );

  	 
  }

  const submitButton = async (e) => {
    e.preventDefault();
   
    
    const data = {id:1,settings:form,type:'branding'};
    const url = `${backendUrl}/tenent/save-settings`;
  	await fetchWrapper.post(url,data)

  	toast({
  		 	  position: 'top',
  		 	  variant:'top-accent',
          title: 'Congratulation.',
          description: "Record have been uploaded.",
          status: 'info',
          duration: 1000,
          isClosable: true,
        })
    
    
  }
	return(
		<>
			<h4 className="inner_main_heading">Branding</h4>
					

								<Form className="form_section">
									<div className="d-flex">
										<div className="me-20 w-30">
									      	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
										        <Form.Label>Brand Name</Form.Label>
										        <Form.Control 
										        name="brand_name"  value={form.brand_name} onChange={ (e) => handleChange(e)}
										        type="name" />
									      	</Form.Group>
									      	<Image src={`${backendUrl}/upload/tenent/${tenent_id}/brand-logo.jpg?$(new Date())`} width="100%"  />
									      	<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
										        <Form.Label>Add Logo</Form.Label>
										        <Form.Control name="logo" onChange={ (e) => handleChangeLogo(e)} type="file" />
								    		</Form.Group>
										</div>

										<div className="w-30">
											<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
										        <Form.Label>Industry</Form.Label>
												<Form.Select aria-label="Default select example"
												name="industry_id"  value={form.industry_id} onChange={ (e) => handleChange(e)}
												>
											      	<option>Select Industry</option>
											      	{industryList?.rows?.map(rsAuthor => {

											      		return (<option value={rsAuthor.id}>{rsAuthor.name}</option>)
											      	})}
									    		</Form.Select>
								    		</Form.Group>
								    		<Image src={`${backendUrl}/upload/tenent/${tenent_id}/fav.ico?${new Date()}`}   />
								    		<Form.Group className="field_section" controlId="exampleForm.ControlInput1">
										        <Form.Label>Add Favicon</Form.Label>
										        <Form.Control name="favicon" type="file" onChange={ (e) => handleChangeFav(e)} />
								    		</Form.Group>
										</div>
									</div>

									

						    		<Button className="primary" onClick={(e) => submitButton(e)} variant="primary">Save</Button>
								</Form> 
		</>
	)
}

export default Branding;